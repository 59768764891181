import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Drawer({ drawer, handleDrawer }) {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div
        tabIndex={"-1"}
        className={
          "ant-drawer ant-drawer-right  ps-panel--mobile " +
          (drawer ? "ant-drawer-open" : "")
        }
      >
        <div className="ant-drawer-mask"></div>
        <div
          className="ant-drawer-content-wrapper"
          style={{
            width: "300px",
            transform: drawer ? "translateX(0%)" : "translateX(100%)",
          }}
        >
          <div class="ant-drawer-content">
            <div class="ant-drawer-wrapper-body">
              <div class="ant-drawer-body">
                <div class="ps-drawer ps-drawer--with-menu">
                  <div class="ps-drawer__header">
                    <a class="ps-drawer__close">
                      <i onClick={handleDrawer} class="fa fa-times"></i>
                    </a>
                  </div>
                  <div class="ps-drawer__wrapper">
                    <div class="ps-drawer__menu">
                      <ul class="menu--accordion">
                        <li class="menu__item menu__item--has-children ">
                          <Link to={"/"}>
                            <a onClick={handleDrawer} class="menu__toggle">
                              <span class="menu__text">Home</span>
                              <i class="icon-chevron-down menu__icon--down"></i>
                            </a>
                          </Link>
                        </li>

                        <li>
                          <p onClick={handleToggle} class="menu__toggle">
                            <span class="menu__text">About Us</span>
                            {open ? (
                              <i class="fa fa-chevron-up menu__icon--down"></i>
                            ) : (
                              <i class="fa fa-chevron-down menu__icon--down"></i>
                            )}
                          </p>
                        </li>
                        {/* About us links starts here */}
                        {open && (
                          <>
                            <li>
                              <Link to={"/our-journey"}>
                                <a
                                 onClick={()=>{
                                  handleToggle();
                                  handleDrawer();
                                }}
                                >
                                  <span class="menu__text">Our Journey</span>
                                </a>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/our-team"}>
                                <a  
                                onClick={()=>{
                                  handleToggle();
                                  handleDrawer();
                                }}>
                                  <span class="menu__text">Our Team</span>
                                </a>
                              </Link>
                            </li>
                            <li>
                              <Link to={"/founder-desk"}>
                                <a  onClick={()=>{
                                  handleToggle();
                                  handleDrawer();
                                }}
                                >
                                  <span class="menu__text">Founder’s Desk</span>
                                </a>
                              </Link>
                            </li>
                          </>
                        )}

                        {/* About us links ends here */}
                        <li class="menu__item menu__item--has-children ">
                          <Link to={"/franchise"}>
                            <a onClick={handleDrawer} class="menu__toggle">
                              <span class="menu__text">Franchisee</span>
                              <i class="icon-chevron-down menu__icon--down"></i>
                            </a>
                          </Link>
                        </li>
                        {/* <li class="menu__item menu__item--has-children ">
                          <a  class="menu__toggle">
                            <span class="menu__text">Products</span>
                            <i class="icon-chevron-down menu__icon--down"></i>
                          </a>
                        </li> */}
                        <li class="menu__item menu__item--has-children">
                          <Link to={"/store"}>
                            <a onClick={handleDrawer} class="menu__toggle">
                              <span class="menu__text">Our Stores</span>
                            </a>
                          </Link>
                        </li>
                        <li class="menu__item menu__item--has-children">
                          <Link to={"/blog"}>
                            <a onClick={handleDrawer} class="menu__toggle">
                              <span class="menu__text">Our Blog</span>
                            </a>
                          </Link>
                        </li>
                        <li class="menu__item menu__item--has-children">
                          <Link to={"/gallery"}>
                            <a onClick={handleDrawer} class="menu__toggle">
                              <span class="menu__text">Gallery</span>
                            </a>
                          </Link>
                        </li>
                        <li class="menu__item menu__item--has-children">
                          <Link to={"/career"}>
                            <a onClick={handleDrawer} class="menu__toggle">
                              <span class="menu__text">Career</span>
                            </a>
                          </Link>
                        </li>
                        <li class="menu__item">
                          <Link to={"/contact"}>
                            <a onClick={handleDrawer} class="menu__toggle">
                              <span class="menu__text">Contact Us</span>
                            </a>
                          </Link>
                        </li>
                        <li class="menu__item">
                          <Link to={"/franchise"}>
                            <button
                              onClick={handleDrawer}
                              class="ps-btn ps-btn--warning"
                            >
                              Get Franchisee
                            </button>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div class="ps-drawer__footer">
                      <figure>
                        <p class="ps-text--contact-number">
                          Need help? <strong>91+ 95079 55555</strong>
                        </p>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

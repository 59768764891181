import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

function SingleCategory({title,image }) {
  return (
    <>
      <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
        <article class="ps-post ps-post--grid cat" style={{backgroundColor:"#fff"}}>
          <div class="ps-post__thumbnail cat">
            <Link >
              <a
                class="ps-post__overlay"
                // href="/post/the-latest-tests-of-popular-masks-in-accordance-with-cv2s-standards"
              ></a>

              <img src={image} alt="img" />
            </Link>
          </div>
          <div class="ps-post__wrapper  pl-4 pr-4 pb-5" >
            <div class="ps-post__content text-center" >
              <Link >
                <a
                  class="ps-post__title"
                  href="/post/the-latest-tests-of-popular-masks-in-accordance-with-cv2s-standards"
                >
                  {title}
                </a>
              </Link>

              <Link >
                <button class="ps-btn ps-btn--blue mt-4">Details</button>
              </Link>
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

SingleCategory.defaultProps = {
  image: "https://mymedi.noudeveloper.com/uploads/p1_bce777554b.jpg",
  title: "Category one",
 
};

SingleCategory.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
 
};

export default SingleCategory;

import React, { useEffect } from "react";
import SingleBanner from "../../component/SingleBanner";
import AboutInfo from "../../component/About/AboutInfo";
import AboutFullBanner from "../../component/About/AboutFullBanner";
import AboutProject from "../../component/About/AboutProject";
import AboutVideo from "../../component/About/AboutVideo";
import AboutTestimonial from "../../component/About/AboutTestimonial";
import PageHelmet from "../../component/common/Helmet";
import TestimonialCarousel from "../../component/Slider/TestimonialCarousel";
import FranchiseFullBanner from "../../component/Franchise/FranchiseFullBanner";

import aboutImg from "../../assets/drugpoint/banner/drugpoint-banner-6.jpg"

 const AboutPage=()=> {

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
  },[]);

  return (
     <React.Fragment>
      <PageHelmet title={"About"} />
      <main className="ps-page ps-page--inner">
        <div className="ps-page__content">
         
          <div className="ps-about">
          <div className="ps-about__content">
          <FranchiseFullBanner image={aboutImg} />
            </div>
            <div className="container">
                {/* Start Top Single Banner with Carousel */}
              <SingleBanner />
                {/* End Top Single Banner with Carousel */}

                  {/* Start About info */}
              <AboutInfo />
               {/* End About info */}
            </div>
            <div className="ps-about__content">
              <AboutFullBanner />
              <AboutProject />
              <AboutVideo />

            </div>
            {/* <AboutTestimonial /> */}
            <TestimonialCarousel />
          </div>
        </div>
        {/* <NewsLetter /> */}
      </main>
      </React.Fragment>
   
  );
};

export default AboutPage;

import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

function SingleBlog({ category, title, paragraph, image }) {
  return (
    <>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
        <article class="ps-post ps-post--grid">
          <div class="ps-post__thumbnail">
            <Link to={`/blog/${category}`}>
              <a
                class="ps-post__overlay"
                // href="/post/the-latest-tests-of-popular-masks-in-accordance-with-cv2s-standards"
              ></a>

              <img src={image} alt="img" />
            </Link>
            {/* <div class="ps-post__categories">
              <a href="/blog">{category}</a>
            </div> */}
          </div>
          <div class="ps-post__wrapper pl-4 pr-4 pb-5">
            <div class="ps-post__content ps-footer--content">
              <Link to={`/blog/${category}`}>
                <a
                  class="alert-link h3 fs-3"
                  // href="/post/the-latest-tests-of-popular-masks-in-accordance-with-cv2s-standards"
                >
                  {title}
                </a>
              </Link>
              {/* <div class="ps-post__meta">
                <span class="ps-post__created-at">Jun 13, 2021</span>
                <a class="ps-post__author" href="/blog">
                  Alfredo Austin
                </a>
              </div> */}
              <p class="text-secondary">{paragraph}</p>
              <Link to={`/blog/${category}`}>
                <button class="ps-btn ps-btn--blue mt-3">Read More</button>
              </Link>
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

SingleBlog.defaultProps = {
  image: "https://mymedi.noudeveloper.com/uploads/p1_bce777554b.jpg",
  title: " The latest tests of popular masks in accordance with CV2s standards",
  category: "uncategoried",
  paragraph:
    " dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
};

SingleBlog.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  paragraph: PropTypes.string,
};

export default SingleBlog;

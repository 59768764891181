import React from "react";
import SingleBlog from "./SingleBlog";

import { blogData } from "../pages/BlogPage/blogData";

import PropTypes from "prop-types";

function BlogList({ title, startIndex }) {
  return (
    <>
      <section className="ps-section--from-blog pt-5 pb-5">
        <div className="container">
          <div class="ps-section__header">
            <h3>{title}</h3>
          </div>
          <div className="ps-section__content">
            <div className="ps-blog-items row" data-columns="3">
              {blogData
                .slice(startIndex, startIndex + 3)
                .map((value, index) => (
                  <SingleBlog
                    key={index}
                    image={value.image}
                    title={value.title}
                    paragraph={value.paragraph}
                    category={value.category}
                  />
                ))}

              {/* <SingleBlog />
             <SingleBlog />
             <SingleBlog /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

BlogList.defaultProps = {
  title: "From our blog",
  startIndex: 0,
};

BlogList.propTypes = {
  title: PropTypes.string,
  startIndex: PropTypes.number,
};

export default BlogList;

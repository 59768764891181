import React from "react";

import cat1 from "../assets/drugpoint/drug/ayurvedic.jpg";
import cat2 from "../assets/drugpoint/drug/baby-care.webp";
import cat3 from "../assets/drugpoint/drug/hair-care.jpg";
import cat4 from "../assets/drugpoint/drug/healthcare-device.png";
import cat5 from "../assets/drugpoint/drug/skincare.jpg";
import cat6 from "../assets/drugpoint/drug/sanitizer.jpg";
import cat7 from "../assets/drugpoint/drug/diabetic-care.jpg";
import cat8 from "../assets/drugpoint/drug/vitamin-supliment.jpg";
import SingleCategory from "./SingleCategory";

const catData = [
  {
    image: cat1,
    title: "Ayurvedic and Herbal Medicines",
  },
  {
    image: cat2,
    title: "Baby Care",
  },
  {
    image: cat3,
    title: "Pharmacy",
  },
  {
    image: cat4,
    title: "Healthy Food and Drinks",
  },
  {
    image: cat5,
    title: "Health Devices",
  },
  {
    image: cat6,
    title: "Personal Care",
  },
  {
    image: cat7,
    title: "Sexual Wellness",
  },
  {
    image: cat8,
    title: "Over The Counter Medicines",
  },
  {
    image: cat8,
    title: "Home Care Essentials",
  },
  {
    image: cat8,
    title: "Chocolate and Beverages",
  },
];

function CatList() {
  return (
    <>
      <section
        className="ps-section--from-blog pt-5 pb-5"
        style={{ backgroundColor: "#f3f6f4" }}
      >
        <div className="container">
          <div class="ps-section__header">
            <h3>Categories</h3>
          </div>
          <div className="ps-section__content">
            <div
              className="ps-blog-items row justify-content-center"
              data-columns="3"
            >
              {catData.map((value, index) => (
                <SingleCategory
                  key={index}
                  image={value.image}
                  title={value.title}
                />
              ))}
            </div>
            <div class="text-center">
              <a class=" ps-btn ps-btn--blue ps-categories__show" >
                Show all
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CatList;

import React from "react";
import dentistImg from "../../assets/img/icon/dentistry-icon-2.png";

export default function InfoIconBlock() {
  return (
    <>
     
        <div class="col-12 col-md-4 p-0">
          <div
            // class="d-flex justify-content-center align-items-center"
            class="ps-block--about"
          >
            <div class="ps-block__icon">
              <img
                //   src="/static/img/icon/dentistry-icon-2.png"
                src={dentistImg}
                alt=""
              />
            </div>
            <h4 class="ps-block__title">
              Sonic cleaning <br />
              and whitening power
            </h4>
            <div class="ps-block__subtitle">
              At the same time, it protects and whitens
            </div>
          </div>
        </div>
      
    </>
  );
}

// ProfileCard.js

import React from 'react';
import './ProfileCard2.css';

const ProfileCard2 = ({ imageUrl, name, designation }) => {
  return (
    <div className="profile-card">
      <img className="profile-image" src={imageUrl} alt={name} />
      <div className="profile-details">
        <h2 className="profile-name">{name}</h2>
        <p className="profile-designation">{designation}</p>
      </div>
    </div>
  );
};

export default ProfileCard2;

import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slides.css";

import TopBannerTwo from "../TopBannerTwo";

import banner1 from "../../assets/drugpoint/banner/drug-point-web.png";
import banner2 from "../../assets/drugpoint/banner/drug-point-hair-care.png";
// import banner3 from "../../assets/drugpoint/banner/drug-point-banner-3.jpeg";

export default function TopBannerCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  

  return (
    <>
      <div className="ps-carousel mb-5 ">
        <Slider {...settings}>
          {/* <TopBannerTwo image={banner3} /> */}
          <TopBannerTwo
            image={banner2}
            line1={""}
            line2={""}
          />
          <TopBannerTwo
            image={banner1}
            line1={""}
            title2={""}
            line2={""}
          />
        </Slider>
      </div>
    </>
  );
}

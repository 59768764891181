import React from "react";

const InfoHeader=()=>{

    return (
        <>
         <h2 class="ps-about__title">
          Effective and reliable <br />
          protection for your teeth
        </h2>
        <p class="ps-about__subtitle">
          The brush handle fits perfectly in the hand, is slim and beautifully
          made.
        </p>
        </>
    )
};

export default InfoHeader;

import ReasonCountRightSide from "./ReasonCountRightSide";

const ReasonCount = () => {
  return (
    <>
      <section class="ps-about__project">
        <div class="container">
            <ReasonCountRightSide />
        </div>
      </section>
    </>
  );
};

export default ReasonCount;

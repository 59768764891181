import React, { useCallback, useState } from "react";
import logo from "../../assets/img/logo.png";
import Drawer from "../Drawer";

export default function MobileTopHeader() {
  const [drawer, setDrawer] = useState(false);

  const handleDrawer = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  return (
    <>
      <header
        className="header header--mobile header--sticky mb-5"
        data-sticky="true"
        id="header-sticky-mobile"
      >
        <div className="header__left">
          <a className="ps-logo" href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <div className="header__right">
          <a
            className="header__search"
            //   href="#"
          >
            <i onClick={handleDrawer} className="fa fa-bars"></i>
          </a>
        </div>
      </header>
      <Drawer drawer={drawer} handleDrawer={handleDrawer} />
    </>
  );
}

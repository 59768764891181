import React from "react";
import { Helmet } from "react-helmet";

import WhyChoose from "./WhyChoose";
import RightSideJourney from "./RightSideJourney";
import WelcomePage from "./WelcomePage";
import faqImg from "../../assets/img/welcome-drug-2.jpg";
import VisionComponent from "./VisionComponent";
import ReasonCount from "./ReasonCount";
import JourneyAboutUs from "./JourneyAboutUs";

const OurJourneyPage = () => {
  
  React.useEffect(() => {
    // Scroll to the top with smooth behavior when the component mounts
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Our Journey - Drug Point</title>
      </Helmet>
      <main className="ps-page ps-page--inner">
        <div className="ps-page__content">
          <WelcomePage backgroundImage={faqImg} />

          <div className="ps-about">
            <div className="ps-about__content">
            <JourneyAboutUs />
              <WhyChoose />
              
              <ReasonCount />
              {/* <RightSideJourney /> */}
              <VisionComponent />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default OurJourneyPage;

import React from "react";
import { Helmet } from "react-helmet";
import ContactForm from "../../component/Contact/ContactForm";
import doctorImg from "../../assets/img/career.jpg";
import CareerForm from "../../component/Contact/CareerForm";
import WelcomePage from "../OurJourney/WelcomePage";
import careerImg from "../../assets/img/career-banner.jpg";

const CareerPage = () => {

    React.useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },[]);
    
  return (
    <React.Fragment>
      <Helmet>
        <title>Career - Drug Point</title>
      </Helmet>
      <WelcomePage
      backgroundImage={careerImg}
      title="Career"
      />
      <div className="ps-page ps-page--inner ps-page--notfound">
        <div className="container">
          <div class="row mb-80 mt-80">
            <div class="col-12  col-lg-6">
                <h2>
                    Career
                </h2>
                <p
                style={{
                    color:"#5b6c8f"
                }}
                >
                Drug Point is the emerging private medical retailer in Bihar that has been offering high-quality medical services using state-of-the-art services in Patna, Bihar, since 2019.
                </p>
                <p
                 style={{
                    color:"#5b6c8f"
                }}
                >
                We are renowned for the most complex portfolio of medicine and healthcare essentials providers: pharmacy, ayurvedic and herbal products, health care, personal care, child care, healthy food and drinks.
                </p>
                <p
                 style={{
                    color:"#5b6c8f"
                }}
                >
                Our mission is to be the leading healthcare solution provider in Bihar, delivering accessible and high-quality medical services and products to every individual in the community. We aim to improve the overall well-being of our customers by offering a comprehensive range of pharmaceuticals and healthcare essentials while prioritizing their health, convenience, and trust.
                </p>
            
              <CareerForm title={"Career"} />
            </div>
            <div class="col-12  col-lg-6">
              <img
                //   src="/static/img/cartoon-doctor.jpg"
                src={doctorImg}
                alt="cartoon-doctor"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CareerPage;

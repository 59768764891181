import React from "react";

import PropTypes from "prop-types";

const AccordionTab2 = ({ buttonId, title, paragraph }) => {
  const [expanded, setExpanded] = React.useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <div class="accordion-item">
        <button onClick={toggleExpanded} id={buttonId} aria-expanded={expanded}>
          <span class="accordion-title">
           {title}
          </span>
          <span class="icon" aria-hidden="true"></span>
        </button>
        <div class="accordion-content">
          <p>{paragraph}</p>
        </div>
      </div>
    </>
  );
};

AccordionTab2.defaultProps = {
  buttonId: "accordion-button-1",
  title: " Why is the moon sometimes out during the day?",
  paragraph:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.",
};

AccordionTab2.propTypes = {
    buttonId: PropTypes.string.isRequired,
    title: PropTypes.string,
    paragraph: PropTypes.string,
}

export default AccordionTab2;

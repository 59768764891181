import React, { useEffect } from "react";
import SingleBanner from "../../component/SingleBanner";
import OurStores from "./OurStores";
import PageHelmet from "../../component/common/Helmet";
import FranchiseFullBanner from "../../component/Franchise/FranchiseFullBanner";
import TestimonialCarousel from "../../component/Slider/TestimonialCarousel";

import aboutImg from "../../assets/drugpoint/banner/drugpoint-banner-9.jpeg"


export default function StorePage() {

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <React.Fragment>
        <PageHelmet title={"Store"} />
        <main className="ps-page ps-page--inner">
          <div className="ps-page__content">
            <div className="ps-about">
            <div className="ps-about__content">
             <FranchiseFullBanner
              image={aboutImg}
              title={"Get Franchisee"}
              description={"We provide comprehensive training and ongoing support, equipping you with the knowledge and skills to excel in managing your own pharmacy store."}
               />
              </div>
              <div className="container">
                {/* Start Top Single Banner with Carousel */}
                {/* <SingleBanner /> */}
                {/* End Top Single Banner with Carousel */}
              </div>
              <div className="ps-about__content">
                <OurStores />
              </div>
              <div className="container">
                <TestimonialCarousel />
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    </>
  );
}

import React, { useEffect } from "react";

import img1 from "../../assets/img/blog/p6.jpg";
import PageHelmet from "../../component/common/Helmet";
import BlogList from "../../component/BlogList";

const ReleaseStress = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <PageHelmet title={"Simple Steps To Soothe Stress"} />
      <div class="ps-page ps-page--inner ps-page--post">
        <div class="container mt-5 pt-5">
          {/* <BlogDetailHeader /> */}
          <div class="ps-page__content">
            <div class="ps-layout__right">
              <div class="ps-blog">
                <div class="ps-post ps-post--sidebar ps-post--detail">
                  {/* Blog Detail Post Header Start Here */}
                  {/* <BlogDetailPostHeader /> */}
                  <h1 class="ps-post__title">Simple Steps To Soothe Stress</h1>
                  {/* Blog Detail Post Header End Here */}

                  {/* Blog Post Banner Starts Here */}
                  <div class="ps-blog__banner">
                    <img
                      src={img1}
                      // src="https://mymedi.reactstorefronts.com/static/img/blog/blog13-992x525.jpg"
                      alt=""
                    />
                  </div>
                  {/* Blog Post Banner Ends Here */}

                  <p class="ps-blog__text-large">
                    The hectic lifestyle and the fast-paced world in which we
                    are living today often leave us stressed and anxious. Stress
                    is part and parcel of life from minor challenges to major
                    crises. The situations around us cannot be controlled but
                    our response to them can be. Excessive stress can affect our
                    well-being, so it is essential to have effective stress
                    relievers that can calm our minds and body. These simple
                    steps can be followed to soothe stress and be calm.{" "}
                  </p>
                  <p class="ps-blog__text-large"> Deep Breathing</p>
                  <p class="ps-blog__text">
                    Slow and deep breaths activate the body's relaxation
                    response and lower blood pressure and heart rate. You will
                    feel at peace by focusing on your breath. Tune in to your
                    favorite song and just forget all stress and worries. Music
                    has the capability to make everything seem alright.
                    Classical music can be incredibly relaxing before bedtime.
                  </p>

                  <p class="ps-blog__text-large"> Take A Stroll</p>
                  {/* <p class="ps-blog__text-large">Adequate Sleep</p> */}
                  <p class="ps-blog__text">
                    Go for a quick stroll around your block when you feel
                    overwhelmed or anxious. Spending some time outside will
                    definitely boost your mood. A bright sunny day can cheer up
                    people. Bright light is advised for people who suffer from
                    depression.
                  </p>
                  <p class="ps-blog__text-large">Close Your Eyes</p>
                  <p class="ps-blog__text">
                    Just close your eyes and take a break from a busy office or
                    chaotic surroundings. It is a simple way to regain calm and
                    focus. A squeeze ball is an easy, and non-violent way to
                    relieve stress. On a bad day when you want to strangle your
                    boss or an irritating neighbor, try a squeeze ball. Take out
                    some alone time for yourself to gather your thoughts and
                    clear your head. Declutter your desk, room, office, and
                    life. You will feel calm, focused, and in better control of
                    your situation.
                  </p>

                  <p class="ps-blog__text-large">Do Yoga And Meditate</p>
                  <p class="ps-blog__text">
                    Yoga is a great way to release stress and exercise. Just a
                    few minutes of peace are needed to reap the benefits of
                    meditation. Laughter is one of the easiest, silliest, and
                    cheapest ways to beat stress. Procrastination negatively
                    impacts your productivity and leaves you anxious to catch up
                    on things. Make a to-do list according to your priorities
                    and try to follow it. Having a pet reduces your stress and
                    boosts your mood. Your furry friends can brighten your rough
                    day. Start creating boundaries in your personal and
                    professional life. Prioritize and learn to say no to things
                    that unnecessarily add to your burden and stress.
                  </p>

                  <BlogList startIndex={2} title={"Related blog"} />
                  <p className="ps-blog__text">
                    Phone and social media addiction often result in stress and
                    anxiety. It impacts your sleep also. Limiting screen time
                    can help reduce stress and improve sleep. While juggling
                    your personal and professional commitments, take some
                    quality time for yourself and your family to relax and
                    enjoy.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReleaseStress;

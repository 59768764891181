import React from "react";

import PropTypes from "prop-types";

const FranchiseStepHeader=({title1,title2,subTitle})=>{

    return (
        <>
         <h2 class="ps-about__title">
            {title1} <br />
            {title2}
          {/* Effective and reliable <br />
          protection for your teeth */}
        </h2>
        <p 
        // class="ps-about__subtitle"
        class="ps-blog__text text-secondary text-center"
        >
            {subTitle}
          {/* The brush handle fits perfectly in the hand, is slim and beautifully
          made. */}
        </p>
        </>
    )
};

FranchiseStepHeader.defaultProps={
    title1:"Effective and reliable ",
    title2:"protection for your teeth",
    subTitle:" The brush handle fits perfectly in the hand, is slim and beautifully made.",
};

FranchiseStepHeader.propTypes = {
    title1: PropTypes.string,
    title2:PropTypes.string,
    subTitle: PropTypes.string,
}

export default FranchiseStepHeader;

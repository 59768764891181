import React from "react";
import awardImg from "../../assets/img/icon/team.png";

import toothbrushImg from "../../assets/img/icon/target.png";
import drugStore from "../../assets/img/icon/visionary.png";
import drugCompany from "../../assets/img/icon/products.png";

const VisionComponent = () => {
  return (
    <>
      <div className="container">
        <div className="ps-about--info mt-5 pt-5">
          <h2 class="ps-about__title">Mission, Vision & Value</h2>

          <div className="ps-about__extent">
            <div class="row m-0">
              <div class="col-12 col-md-4 p-0">
                <div class="ps-block--about">
                  <div class="ps-block__icon">
                    <img
                      //   src="/static/img/icon/toothbrush-icon-2.png"
                      src={toothbrushImg}
                      alt=""
                    />
                  </div>
                  <h4 class="ps-block__title"> Mission</h4>
                  <div className="ps-block__subtitle">
                    Our mission is to be the leading healthcare solution
                    provider in Bihar, delivering accessible and high-quality
                    medical services and products to every individual in the
                    community. We aim to improve the overall well-being of our
                    customers by offering a comprehensive range of
                    pharmaceuticals and healthcare essentials while prioritizing
                    their health, convenience, and trust.
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 p-0">
                <div class="ps-block--about">
                  <div class="ps-block__icon">
                    <img
                      //   src="/static/img/icon/award-icon-2.png"
                      src={drugStore}
                      alt="drug-store"
                    />
                  </div>
                  <h4 class="ps-block__title">Vision</h4>
                  <div className="ps-block__subtitle">
                    Our vision is to create a healthier and happier Bihar. We
                    strive to become the most trusted and reliable medical
                    retail chain by expanding our reach, adopting the latest
                    technology, and setting new standards in the healthcare
                    industry. We envision a future where every person in Bihar
                    can access affordable and top-notch healthcare products and
                    services without any compromise on quality.
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 p-0">
                <div class="ps-block--about">
                  <div class="ps-block__icon">
                    <img
                      //   src="/static/img/icon/toothbrush-icon-2.png"
                      src={drugCompany}
                      alt="drug-company"
                    />
                  </div>
                  <h4 class="ps-block__title">Value</h4>
                  <div className="ps-block__subtitle">
                    Bihar's fastest-growing medical retail chain, "Drug Point,"
                    is dedicated to exceptional healthcare services,
                    prioritizing customer well-being through quality products
                    and unwavering integrity. We cultivate a compassionate
                    culture, reflecting genuine concern for our patrons' health
                    and satisfaction in every interaction. Our commitment to
                    innovation allows us to consistently meet the evolving needs
                    of our community, ensuring accessible, reliable, and
                    convenient healthcare.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisionComponent;

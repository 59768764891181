import React, { useEffect } from "react";
import SingleBlog from "../../component/SingleBlog";
import SingleBanner from "../../component/SingleBanner";
import { blogData } from "./blogData";
import PageHelmet from "../../component/common/Helmet";
import FranchiseFullBanner from "../../component/Franchise/FranchiseFullBanner";

import aboutImg from "../../assets/drugpoint/banner/drugpoint-banner-7.jpg"


const BlogPage2 = () => {

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);
  
  return (
    <>
    <React.Fragment>
      <PageHelmet title={"Blog"} />
      <div class="ps-page ps-page--inner">
      <div className="ps-page__content">
          <div className="ps-about">
            <FranchiseFullBanner image={aboutImg} />
      <div className="container">
              <SingleBanner />
            </div>
            </div>
            </div>
        <div class="container pt-5 mt-lg-5 mt-md-5 mt-sm-5">
          <div class="ps-page__header pt-5 mt-lg-0 mt-md-2 mt-sm-5">
           
          {/* <ul class="breadcrumb"><li><a href="/">Home</a></li><li>Blog Grid</li></ul> */}
            <h3 class="ps-page__heading text-center">Blog List</h3>
          </div>
          <div className="ps-section__content">
            <div class="ps-blog">
            <div className="ps-blog-items row justify-content-center" data-columns="3">
              {
                blogData.map((value,index)=>(
                  <SingleBlog key={index} image={value.image} title={value.title} paragraph={value.paragraph} category={value.category} />
                ))
              }
              {/* <SingleBlog />
              <SingleBlog />
              <SingleBlog />
              <SingleBlog />
              <SingleBlog />
              <SingleBlog /> */}
            </div>
            </div>
           
          </div>
        </div>
      </div>
      </React.Fragment>
    </>
  );
};

export default BlogPage2;

import Layout from "./Layout";

import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import BlogDetailPage from "./pages/BlogPage/BlogDetailPage";
import FranchisePage from "./pages/FranchisePage";
import PrivacyPage from "./pages/PrivacyPage";
import FaqPage from "./pages/FaqPage";
import TermsPage from "./pages/TermsPage";
import StorePage from "./pages/StorePage";
import BlogPage2 from "./pages/BlogPage/BlogPage2";
import EnquiryPage from "./pages/EnquiryPage";
import ImmunePage from "./pages/BlogDetailPage/immunePage";
// import JointBonePage from "./pages/BlogDetailPage/supplementPage";
import MultiSupplement from "./pages/BlogDetailPage/supplementPage";
import CareHeart from "./pages/BlogDetailPage/careHeart";
import BoneJoint from "./pages/BlogDetailPage/boneJoint";
import SleepTips from "./pages/BlogDetailPage/sleepTips";
import ReleaseStress from "./pages/BlogDetailPage/releaseStress";
import CareerPage from "./pages/Career";
import GalleryPage from "./pages/Gallery";
import OurTeamPage from "./pages/OurTeam";
import OurJourneyPage from "./pages/OurJourney";
import FounderDesk from "./pages/FonderDesk";

function App() {
  return (
    <>
      <Routes>
       
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="store" element={<StorePage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="franchise" element={<FranchisePage />} />
            <Route path="blog" element={<BlogPage2 />} />
            <Route path="blog/:blogId" element={<BlogDetailPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="terms" element={<TermsPage />} />
            <Route path="enquiry" element={<EnquiryPage />} />
            <Route path="*" element={<ErrorPage />} />
           
           <Route path="career" element={<CareerPage />} />
           <Route path="gallery" element={<GalleryPage />} />

           <Route path="our-team" element={<OurTeamPage />} />
           <Route path="our-journey" element={<OurJourneyPage />} />
           <Route path="founder-desk" element={<FounderDesk />} />
            {/* Blog detail pages */}
            <Route path="blog/immune-improve" element={<ImmunePage />} />
            <Route path="blog/multi-supplement" element={<MultiSupplement />} />
            <Route path="blog/care-heart" element={<CareHeart />} />

            <Route path="blog/bone-care" element={<BoneJoint />} />
            <Route path="blog/sleep-tips" element={<SleepTips />} />
            <Route path="blog/release-stress" element={<ReleaseStress />} />
          </Route>
       
      </Routes>
    </>
  );
}

export default App;

import React from 'react';
import "./modal.css";


const Modal = ({ show }) => {
  return (
    <div className={`modal ${show ? 'show' : ''}`}>
      <div className="modal-content">
        <div className="progress-bar">
          <div className="spinner"></div>
        </div>
        <p>Processing...</p>
      </div>
    </div>
  );
};

export default Modal;


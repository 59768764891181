import React from "react";

import featuredImg1 from "../../assets/drugpoint/category/cat1.png";
import featuredImg2 from "../../assets/drugpoint/category/cat2.png";
import featuredImg3 from "../../assets/drugpoint/category/cat3.png";
import featuredImg4 from "../../assets/drugpoint/category/CAT4.png";
import featuredImg5 from "../../assets/drugpoint/category/cat5.png";
import featuredImg6 from "../../assets/drugpoint/category/cat6.png";

import SingleFeatureProduct from "./SingleFeatureProduct";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";


const featureProdList=[
  {
    image:featuredImg1,
    text_class:""
  },
  {
    image:featuredImg2,
    text_class:""
  },
  {
    image:featuredImg3,
    text_class:"text-white"
  },
  {
    image:featuredImg4,
    text_class:""
  },
  {
    image:featuredImg5,
    text_class:""
  },
  {
    image:featuredImg6,
    text_class:"text-white"
  },
];

 const FeaturedProduct =({featureProdList,title})=> {
  return (
    <>
      <section class="ps-home-promotions container pt-5 pb-5">

        <div class="ps-promo ps-promotions-2 ps-home-two-promotion-two">
        <h3 class="ps-section__title text-center mt-4">{title}</h3>
          <div class="row">
           {
            featureProdList.map((item,index)=>(
              <SingleFeatureProduct key={index} img={item.image} text_class={item.text_class} />
            ))
           }
           
          </div>
          <div class="text-center">
            <Link
            to={"/store"}
            >
            <a class=" ps-btn ps-btn--blue ps-categories__show" >
                View All
              </a>
            </Link>
             
            </div>
        </div>
      </section>
    </>
  );
};

FeaturedProduct.defaultProps = {
  featureProdList: featureProdList,
  title:"Categories"
};

FeaturedProduct.propTypes = {
  featureProdList: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    text_class: PropTypes.string,
  })),
  title:PropTypes.string,
  
}

export default FeaturedProduct;

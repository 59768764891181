import React from "react";


export default function ContactAddress(){

    return (
        <>
         <div class="col-12 col-lg-6">
                  <div class="ps-contact__info">
                    <h2 class="ps-contact__title">How can we help you?</h2>
                    <p class="ps-contact__text">
                      We are at your disposal 7 days a week!
                    </p>
                    {/* <h3 class="ps-contact__fax">0020 500 – MYMEDI – 000</h3> */}
                    <div class="ps-contact__work">
                      Monday – Friday: 9:00-20:00
                      <br />
                      Saturday: 11:00 – 15:00
                    </div>
                    <div class="ps-contact__email">
                      <a href="mailto:contact@example.com">
                        contact@example.com
                      </a>
                    </div>
                    <ul class="ps-social">
                      <li>
                        <a class="ps-social__link facebook" href="#">
                          <i class="fa fa-facebook"> </i>
                          <span class="ps-tooltip">Facebook</span>
                        </a>
                      </li>
                      <li>
                        <a class="ps-social__link instagram" href="#">
                          <i class="fa fa-instagram"></i>
                          <span class="ps-tooltip">Instagram</span>
                        </a>
                      </li>
                      <li>
                        <a class="ps-social__link youtube" href="#">
                          <i class="fa fa-youtube-play"></i>
                          <span class="ps-tooltip">Youtube</span>
                        </a>
                      </li>
                      <li>
                        <a class="ps-social__link pinterest" href="#">
                          <i class="fa fa-pinterest-p"></i>
                          <span class="ps-tooltip">Pinterest</span>
                        </a>
                      </li>
                      <li>
                        <a class="ps-social__link linkedin" href="#">
                          <i class="fa fa-linkedin"></i>
                          <span class="ps-tooltip">Linkedin</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
        </>
    )
}


import React from "react";

import virusImg from "../../assets/img/icon/virus.svg";
import ribbonImg from "../../assets/img/icon/ribbon.svg";

import thumbnailImg from "../../assets/img/about/goby-tD3GaS9ysF4-unsplash-1.jpg";

export default function AboutVideo() {
  return (
    <>
      <section class="ps-about--video">
        <div class="ps-banner">
          <div class="container">
            <div class="ps-banner__block">
              <div class="ps-banner__content">
                <h2 class="ps-banner__title">
                  Your one and only
                  <br />
                  online pharmacy!
                </h2>
                <div class="ps-banner__desc">
                  Only this week 30% to 50% cheaper!
                </div>
                <div class="ps-banner__btn-group">
                  <div class="ps-banner__btn">
                    <img 
                    // src="/static/img/icon/virus.svg" 
                    src={virusImg}
                    alt="" />
                    Up to 5 users simultaneously
                  </div>
                  <div class="ps-banner__btn">
                    <img 
                    //  src="/static/img/icon/ribbon.svg"
                    src={ribbonImg}
                     alt="" />
                    Has HEALTH certificate
                  </div>
                </div>
                <a class="ps-banner__shop bg-warning" href="#">
                  About
                </a>
              </div>
              <div class="ps-banner__thumnail">
                <img
                  class="ps-banner__image"
                //   src="/static/img/about/goby-tD3GaS9ysF4-unsplash-1.jpg"
                src={thumbnailImg}
                  alt=""
                />
                <div id="ps-video-gallery">
                  <div
                    class="video"
                    data-html="#video1"
                    data-poster={thumbnailImg}
                  >
                    <a href="">
                      <div class="ps-banner__video">
                        <i class="fa fa-play"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import axios from "axios";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";

import * as Yup from "yup";

import PropTypes from "prop-types";
import { BACKENED_BASE_URL } from "../../backend-constant";
import Modal from "../Modal/modal";

function ContactForm({title}) {

  const [showModal,setShowModal]=useState(false);


  const form = useRef();
  const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const contactSchema=Yup.object().shape({
    name: Yup.string().min(2, "Too short").required("Name is  required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone is required"),
    // subject: Yup.string().min(2, "Too Short").required("Subject is required"),
    message: Yup.string().notRequired("Message is required"),
  });

  const formik=useFormik({
    initialValues:{
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema:contactSchema,
    onSubmit:(values,{resetForm})=>{

      setShowModal(true);

      let data = new FormData();
      data.append("name", values.name);
      data.append("email", values.email);
      data.append("phone", values.phone);
      // data.append("subject", values.subject);
      data.append("message", values.message);
      let config = {
        method: "post",
        url: `${BACKENED_BASE_URL}contact.php`,
        data: data,
      };

      axios.request(config).then((response)=>{
        console.log(response.data);
        setShowModal(false);
        resetForm();
      })
      .catch((error)=>{
        console.log(error);
        setShowModal(false);
        resetForm();
      });


    },
  });


  return (
    <>
      <div class="ps-form--contact ">
        <h2 class="ps-form__title">
          {title}
        </h2>
        <form ref={form} onSubmit={formik.handleSubmit}>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
              {formik.errors.name && formik.touched.name ? (
                      <div class="pl-4 m-0 ml-1 text-danger">{formik.errors.name}</div>
                    ) : (
                      <div class=" pl-4 m-0 ml-1 text-secondary">Name and Surname</div>
                    )}
                <input
                  id="name"
                  name="name"
                  type="text"
                  class="form-control ps-form__input"
                  // placeholder="Name and Surname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
              {formik.errors.email && formik.touched.email ? (
                      <div class="pl-4 m-0 ml-1 text-danger">{formik.errors.email}</div>
                    ) : (
                      <div class="p-0 m-0 pl-4 m-0 ml-1 text-secondary">Email</div>
                    )}
                <input
                  id="email"
                  name="email"
                  type="email"
                  class="form-control ps-form__input"
                  // placeholder="Your E-mail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
              {formik.errors.phone && formik.touched.phone ? (
                      <div class="pl-4 m-0 ml-1 text-danger">{formik.errors.phone}</div>
                    ) : (
                      <div class="p-0 m-0 pl-4 m-0 ml-1 text-secondary">Phone</div>
                    )}
                <input
                  id="phone"
                  name="phone"
                  type="number"
                  class="form-control ps-form__input"
                  // placeholder="Phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
              </div>
            </div>
            <div class="col-12">
              <div class="ps-form__group">
                <textarea
                  id="message"
                  name="message"
                  class="form-control ps-form__textarea"
                  rows="5"
                  placeholder="Message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                ></textarea>
              </div>
            </div>
          </div>
          <div class="ps-form__submit">
            <button
              type="submit"
              value="submit"
              name="submit"
              class="ps-btn ps-btn--blue"
            >
              Send message
            </button>
          </div>
        </form>
      </div>
      <Modal show={showModal}/>
    </>
  );
};

ContactForm.defaultProps={
  title:"Fill up the form if you have any question",
};

ContactForm.propTypes={
  title: PropTypes.string,
}

export default ContactForm;

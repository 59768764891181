// TabComponent.js
import React, { useRef } from "react";
import "./Tabcomponent.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


import { Autoplay } from "swiper/modules";


const storeLocations = [
  {
    id: "rukanpura",
    title: "Rukanpura, Patna",
  },
  {
    id: "saguna-more",
    title: "Saguna More, Patna",
  },
  {
    id: "patliputra",
    title: "Patliputra, Patna",
  },
  {
    id: "raxaul",
    title: "Raxaul",
  },
  {
    id: "begusarai",
    title: "Begusarai",
  },
  {
    id: "bettiah",
    title: "Bettiah",
  },

  {
    id: "sk-puri",
    title: "SK Puri, Patna",
  },
  {
    id: "kanti-factory",
    title: "Kanti Factory, Patna",
  },
  {
    id: "sitamarahi",
    title: "Sitamarahi",
  },
  {
    id: "gaya",
    title: "Gaya",
  },
  {
    id: "koriya-tola-raxaul",
    title: "Koriya Tola, Raxaul",
  },
  {
    id: "anishabad",
    title: "Anishabad, Patna",
  },
  {
    id: "bihta",
    title: "Bihta, Patna",
  },
];

const TabComponent = () => {
  const swiperRef = useRef(null);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="swiper-tabs-container">
      <button className="custom-swiper-button-prev" onClick={handlePrev}>
        {"<"}
      </button>

      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        loop={true}
        modules={[Autoplay]}
        // centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        navigation={{
          nextEl: ".custom-swiper-button-next",
          prevEl: ".custom-swiper-button-prev",
        }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
      >
        {storeLocations.map((tab, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            <div
              id={index}
              key={index}
              className={`tab`}

              // onClick={() => handleTabClick(index)}
            >
              <p
                onClick={() => {
                  scrollToSection(tab.id);
                }}
              >
                {tab.title}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <button className="custom-swiper-button-next" onClick={handleNext}>
        {">"}
      </button>
    </div>
  );
};

export default TabComponent;

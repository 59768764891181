import React from "react";
import BlogDetailHeader from "../../component/Blog/BlogDetailHeader";

import BlogDetailLeft from "./BlogDetailLeft";
import BlogDetailRight from "./BlogDetailRight";

const BlogDetailPage = () => {
  return (
    <>
      <div class="ps-page ps-page--inner ps-page--post">
        <div class="container mt-5 pt-5">
          {/* <BlogDetailHeader /> */}
          <div class="ps-page__content">
          <BlogDetailRight />
           
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailPage;

import React,{useEffect} from "react";
import PrivacyFullBanner from "../../component/Privacy/PrivacyFullBanner";
import PrivacyAccordian2 from "../../component/Privacy/PrivacyAccordion2";
import PageHelmet from "../../component/common/Helmet";


const PrivacyPage=()=>{
    useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      },[]);

    return (
        <>
        <React.Fragment>

       <PageHelmet title={"Privacy Policy"} />
         <main className="ps-page ps-page--inner">
        <div className="ps-page__content">
          <div className="ps-about">
            <div className="ps-about__content">
               <PrivacyFullBanner />
            </div>
            <div class="container">
            {/* <PrivacyAccordian /> */}
            </div>
            <PrivacyAccordian2 />
            </div>
            </div>
            </main>
            </React.Fragment>
        
        </>
    )
};

export default PrivacyPage;


import React from "react";
import PromoBanner from "../../component/PromoBanner";
import TopCategories from "../../component/TopCategories";
import SingleBanner from "../../component/SingleBanner";
import FeaturedProduct from "../../component/FeatureProduct/FeaturedProduct";
import BlogList from "../../component/BlogList";
import TopBannerCarousel from "../../component/Slider/TopBannerCarousel";

import featuredImg1 from "../../assets/drugpoint/store/drug-point-store.jpeg";
import featuredImg2 from "../../assets/drugpoint/store/drug-point-store-2.jpeg";
import { useEffect } from "react";
import PageHelmet from "../../component/common/Helmet";
import CatList from "../../component/CatList";
import TestimonialCarousel from "../../component/Slider/TestimonialCarousel";
import HomeAbout from "../../component/About/HomeAbout";


const featureProdList = [
  {
    image: featuredImg1,
    text_class: "",
  },
  {
    image: featuredImg2,
    text_class: "",
  },
  {
    image: featuredImg1,
    text_class: "text-white",
  },
];

export default function HomePage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
  }, []);

  return (
    <React.Fragment>
      <PageHelmet title={"Home - Drug Point"} />
     
      <TopBannerCarousel />
      <CatList />
      <HomeAbout />
      {/* <FeaturedProduct /> */}
      <PromoBanner />
      {/* <TopCategories /> */}
     
     
      <SingleBanner />

      <FeaturedProduct title={"Our Stores"} featureProdList={featureProdList} />
      {/* <SingleBanner /> */}
     
      <BlogList />
      {/* <div className="container">
      <AboutTestimonial />
      </div> */}
      <div className="container">
      <TestimonialCarousel />
      </div>
    

     
     {/* <SingleReview /> */}
     
    </React.Fragment>
  );
}

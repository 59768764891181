import React, { useEffect } from "react";

import img1 from "../../assets/img/blog/p4.jpg";
import PageHelmet from "../../component/common/Helmet";
import BlogList from "../../component/BlogList";

const BoneJoint = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <PageHelmet title={"Joint And Bone Care"} />
      <div class="ps-page ps-page--inner ps-page--post">
        <div class="container mt-5 pt-5">
          {/* <BlogDetailHeader /> */}
          <div class="ps-page__content">
            <div class="ps-layout__right">
              <div class="ps-blog">
                <div class="ps-post ps-post--sidebar ps-post--detail">
                  {/* Blog Detail Post Header Start Here */}
                  {/* <BlogDetailPostHeader /> */}
                  <h1 class="ps-post__title">
                  Joint And Bone Care
                  </h1>
                  {/* Blog Detail Post Header End Here */}

                  {/* Blog Post Banner Starts Here */}
                  <div class="ps-blog__banner">
                    <img
                      src={img1}
                      // src="https://mymedi.reactstorefronts.com/static/img/blog/blog13-992x525.jpg"
                      alt=""
                    />
                  </div>
                  {/* Blog Post Banner Ends Here */}

                  <p class="ps-blog__text-large">
                    The proper care of bones and joints is of utmost importance.
                    Bones play many roles in the body, like providing structure,
                    protecting organs, and storing calcium. While the base to
                    building strong and healthy bones is during childhood and
                    adolescence, you should take extra care in adulthood to
                    protect your bones. Taking care of your joints will allow
                    you to run, walk, jump, play sports, and enjoy your life to
                    the fullest. These are some very important things that you
                    should follow to safeguard your bones and joints throughout
                    your life.
                  </p>
                  <p className="ps-blog__text-large">Watch Your Weight</p>
                  <p class="ps-blog__text">
                    Manage your weight in order to keep your knees healthy.
                    Overweight people have more chances of getting arthritis.
                    Your knees, ankles, hips, and back get directly affected by
                    carrying extra strain due to weight issues. So, limit the
                    amount of stress on your joints and reduce your risk of
                    joint damage by keeping your weight in check. Eating a
                    healthy diet is always favorable for your joints because it
                    helps in building strong bones and muscles.
                  </p>

                  <p class="ps-blog__text-large">Calcium And Vitamin D.</p>
                  {/* <p class="ps-blog__text-large">Adequate Sleep</p> */}
                  <p class="ps-blog__text">
                    Calcium is required to ensure good health for your bones.
                    Hence, make sure you take in enough calcium in your diet.
                    Some good sources of calcium are dairy products, fish,
                    broccoli, tofu, soy milk, and nuts. Sit in the sun to take
                    the daily dose of vitamin D. Staying sedentary can cause
                    stiffness in your joints. You should try to remain active
                    and avoid staying in one position for too long. Engage in
                    low-impact exercises, like walking, swimming, cycling,
                    strength training, stretching, etc to keep your joints
                    mobile.
                  </p>
                  <p class="ps-blog__text-large">Stay Strong And Muscular</p>
                  <p class="ps-blog__text">
                    Build muscles to support bones and joints. Without enough
                    muscle, your joints take a pounding, mainly your knees. Add
                    strength training exercises to your exercise regime to
                    ensure support and stability for your joints. Maintain
                    correct posture while performing repetitive motions,
                    sitting, and standing. Be extra careful while carrying or
                    lifting heavy objects to protect yourself from a painful
                    injury or joint damage.
                  </p>

                  <p class="ps-blog__text-large">
                    Cut Down Alcohol And Smoking
                  </p>
                  <p class="ps-blog__text">
                    Smoking and excessive alcohol can reduce your bone density
                    and increase your risk for broken bones. Stay hydrated to
                    reduce inflammation and which further reduces the friction
                    between joint surfaces. Keep yourself warm with adequate
                    layers of winter clothing. Keep your home comfortable with
                    heaters etc. Exercise regularly to keep your joints supple
                    and flexible. It also helps with the lubrication of the
                    joints and improves blood flow. Sit in the sun to get enough
                    Vitamin D that helps in building and improving the bones.
                    Eat a well-balanced and nutritious diet with a good amount
                    of Vitamin D and C, Omega 3 fatty acids, and collagen
                    supplements.
                  </p>

                  <BlogList startIndex={0} title={"Related blog"} />
                  <p className="ps-blog__text">
                    Now that winter is here and in this chilly weather, it gets
                    harder for people to manage bone and joint pain. Let's
                    discuss a few tips to manage bone and joint pain in winter-
                    Follow these simple yet effective tips to improve the health
                    of your bones and joints and enjoy not only this season but
                    the beautiful years to come.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BoneJoint;

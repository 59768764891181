import React from "react";
import BlogDetailPostHeader from "../../component/Blog/BlogDetailPostHeader";
import BlogDetailPostRow from "../../component/Blog/BlogDetailPostRow";
import BlogDetailPostRowReview from "../../component/Blog/BlogDetailPostRowReview";

const BlogDetailRight = () => {
  return (
    <>
      <div class="ps-layout__right">
        <div class="ps-blog">
          <div class="ps-post ps-post--sidebar ps-post--detail">
            {/* Blog Detail Post Header Start Here */}
            {/* <BlogDetailPostHeader /> */}
            <h1 class="ps-post__title">
                The latest tests of popular masks in accordance with CV2s
                standards
              </h1>
            {/* Blog Detail Post Header End Here */}

            {/* Blog Post Banner Starts Here */}
            <div class="ps-blog__banner">
              <img
                src="https://mymedi.reactstorefronts.com/static/img/blog/blog13-992x525.jpg"
                alt=""
              />
            </div>
            {/* Blog Post Banner Ends Here */}

            <p class="ps-blog__text-large">
              Sed ac ligula ut leo dignissim blandit non at odio. Mauris et odio
              ut odio elementum fermentum. Nullam dictum diam nisl, vitae
              euismod erat imperdiet in. Vestibulum ac tristique tortor, non
              iaculis dolor. Nunc in tincidunt dui. Proin laoreet imperdiet dui
              et imperdiet. Nam sit amet erat nisl. Nam tristique porttitor
              risus, at fringilla velit. Aliquam erat volutpat.
            </p>
            <p class="ps-blog__text">
              Vivamus et felis vitae dolor imperdiet pulvinar id eu dui. Donec
              ultrices sem nisl, ut porttitor purus scelerisque vel. Morbi eget
              lacinia ligula, eu condimentum urna. Maecenas id nisi a ex
              sollicitudin commodo. Duis imperdiet libero eget nibh volutpat, in
              iaculis felis varius. Nullam ullamcorper bibendum eros quis
              congue. Donec aliquam vel lorem vel tincidunt. Nulla nulla augue,
              pulvinar sit amet faucibus ut, lobortis ut diam. Proin blandit
              scelerisque odio ac consectetur. Nulla a risus fermentum, auctor
              mauris vitae, aliquet arcu.
            </p>
            {/* Blog detail post row start here */}
            {/* <BlogDetailPostRow /> */}
            {/* Blog detail post row end here */}

            <p class="ps-blog__text">
              Vivamus et felis vitae dolor imperdiet pulvinar id eu dui. Donec
              ultrices sem nisl, ut porttitor purus scelerisque vel. Morbi eget
              lacinia ligula, eu condimentum urna. Maecenas id nisi a ex
              sollicitudin commodo. Duis imperdiet libero eget nibh volutpat, in
              iaculis felis varius. Nullam ullamcorper bibendum eros quis
              congue.
            </p>
            {/* Blog detail post row review starts here */}
            <BlogDetailPostRowReview />
              {/* Blog detail post row review ends here */}

            <p class="ps-blog__text-large">
              Sed ac ligula ut leo dignissim blandit non at odio. Mauris et odio
              ut odio elementum fermentum. Nullam dictum diam nisl, vitae
              euismod erat imperdiet in. Vestibulum ac tristique tortor, non
              iaculis dolor. Nunc in tincidunt dui. Proin laoreet imperdiet dui
              et imperdiet. Nam sit amet erat nisl. Nam tristique porttitor
              risus, at fringilla velit. Aliquam erat volutpat.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailRight;

import React from "react";

import doctorImg from "../../assets/img/cartoon-doctor.jpg";

export default function ErrorComponent() {
  return (
    <>
      <div className="ps-page ps-page--inner ps-page--notfound">
      <div className="ps-page__content">
        <div className="container">
          <div class="row mb-80 mt-80">
          <div class="col-12 col-md-6 col-lg-4">
              <img
                //   src="/static/img/cartoon-doctor.jpg"
                src={doctorImg}
                alt="cartoon-doctor"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-8">
              <h1 class="ps-page__name">404</h1>
              <h5>This page has been probably moved somewhere...</h5>
              <p>Please back to homepage or check our offer</p>
              <div>
                <a class="ps-btn ps-btn--primary" href="/">
                  Back to homepage
                </a>
              </div>
            </div>
           
          </div>
        </div>

        </div>
      </div>
    </>
  );
}

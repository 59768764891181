import React, { useEffect } from "react";
import ContactAddress from "../../component/Contact/ContactAddress";
import ContactMap from "../../component/Contact/ContactMap";
import ContactForm from "../../component/Contact/ContactForm";
import PageHelmet from "../../component/common/Helmet";
import FranchiseFullBanner from "../../component/Franchise/FranchiseFullBanner";
import TestimonialCarousel from "../../component/Slider/TestimonialCarousel";

import aboutImg from "../../assets/drugpoint/banner/drugpoint-banner-11.jpeg"


export default function ContactPage() {
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  },[]);
  return (
    <>
    <React.Fragment>
      <PageHelmet title={"Contact Us"} />
      <main className="ps-page ps-page--inner">
        
          <div className="ps-page__content">
          
          <div className="ps-about">
          <FranchiseFullBanner image={aboutImg} />
            </div>
            <div className="ps-contact">
             
            <div className="container">
              <div class="row">
               <ContactAddress />
               <ContactMap />
              </div>
              <div className="mt-5 pt-5">
              <ContactForm />
              </div>
             <TestimonialCarousel />
            </div>
            </div>
         
        </div>
      </main>
      </React.Fragment>
    </>
  );
}

import React, { useEffect } from "react";

import img1 from "../../assets/img/blog/p2.jpg";
import PageHelmet from "../../component/common/Helmet";
import BlogList from "../../component/BlogList";

const MultiSupplement = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <PageHelmet title={"Benefits Of Multi-Nutrient Supplements"} />
      <div class="ps-page ps-page--inner ps-page--post">
        <div class="container mt-5 pt-5">
          {/* <BlogDetailHeader /> */}
          <div class="ps-page__content">
            <div class="ps-layout__right">
              <div class="ps-blog">
                <div class="ps-post ps-post--sidebar ps-post--detail">
                  {/* Blog Detail Post Header Start Here */}
                  {/* <BlogDetailPostHeader /> */}
                  <h1 class="ps-post__title">
                    Benefits Of Multi-Nutrient Supplements
                  </h1>
                  {/* Blog Detail Post Header End Here */}

                  {/* Blog Post Banner Starts Here */}
                  <div class="ps-blog__banner">
                    <img
                      src={img1}
                      // src="https://mymedi.reactstorefronts.com/static/img/blog/blog13-992x525.jpg"
                      alt=""
                    />
                  </div>
                  {/* Blog Post Banner Ends Here */}

                  <p class="ps-blog__text-large">
                    Multivitamins and Multi minerals are the most commonly used
                    health supplements in the world. They have gained popularity
                    in the past few years and more so after covid to increase
                    immunity.
                  </p>
                  <p className="ps-blog__text-large">
                    What Are Multi-Nutrient Supplements?
                  </p>
                  <p class="ps-blog__text">
                    They are preparations that serve as dietary supplements with
                    minerals, vitamins, and other nutritional components.
                    Multi-nutrient supplements can be taken either as
                    prescription medication or over-the-counter drugs also. They
                    come in various forms like tablets, syrup, capsules,
                    powders, and even injectable preparations. It is believed
                    that multivitamins can improve your health, compensate for
                    poor eating habits, and even help in reducing your risk of
                    developing chronic diseases. Let's discuss the benefits of
                    these wonderdrugs as they seem with their many functions-
                  </p>
                  {/* Blog detail post row start here */}
                  {/* <BlogDetailPostRow /> */}
                  {/* Blog detail post row end here */}

                  {/* Blog detail post row review starts here */}
                  {/* <BlogDetailPostRowReview /> */}
                  {/* Blog detail post row review ends here */}

                  <p class="ps-blog__text-large">
                    Better Mood And Energy Levels
                  </p>
                  {/* <p class="ps-blog__text-large">Adequate Sleep</p> */}
                  <p class="ps-blog__text">
                    When your body doesn't get enough vitamins, it has to work
                    harder to perform routine tasks, which causes lower energy
                    levels, lower emotional well-being, and other health
                    problems. Adding multivitamins to your lifestyle and
                    positive changes like exercising and yoga can keep you
                    healthy and energetic.
                  </p>
                  <p class="ps-blog__text-large">
                    Better Bone And Muscle Health
                  </p>
                  <p class="ps-blog__text">
                    The human body needs various vitamins and minerals to keep
                    bones dense and strong. Sometimes your food doesn't provide
                    enough nutrients to keep bones healthy. Taking multi
                    nutrients regularly can help in keeping your bones strong
                    and manage the damaging free radicals which are responsible
                    for muscle aging.
                  </p>

                  <p class="ps-blog__text-large">Better Immune System</p>
                  <p class="ps-blog__text">
                    Vitamins especially A and D have a crucial effect on the
                    immune response according to researchers.
                    The vitamins and minerals can also significantly reduce
                    levels of stress and anxiety that bothers everyone in this
                    fast-paced life. Regular doses of multinutrients can reduce
                    the symptoms of anxiety and depression. After discussing the
                    many benefits of these multinutrients let's have a look at
                    who can be benefitted the most from these-
                  </p>
                  <p class="ps-blog__text-large">Better Eye Health</p>
                  <p class="ps-blog__text">
                    It is believed that age-related degeneration and cataract
                    formation in the eyes can be managed by the intake of
                    antioxidant vitamins and minerals found in multi nutrients.
                    Studies have shown that B vitamins have a significant role
                    in supporting short-term memory function. So, regular multi
                    nutrients can be your key to forgetting that forgetfulness.
                  </p>
                 
                
                  <p class="ps-blog__text-large">Athletes</p>
                  <p class="ps-blog__text">
                    Athletes have more nutrient requirements than the average
                    non-active person to support muscle recovery and
                    fitness-related goals.
                  </p>
                 
                  <p class="ps-blog__text-large">Young Children</p>
                  <p class="ps-blog__text">
                  Supplements are required to reduce the risk of fetal defects
                    and support the growing fetus. A multivitamin high in iron,
                    folic acid, and vitamin D is advised by doctors during
                    pregnancy.
                    It is advisable that all children between the ages of six
                    months to five years be given a supplement containing
                    vitamins A, C, and D.
                    Nutritional needs of the body change with age. All adults
                    over 65 are advised to take daily vitamins D and C, calcium,
                    iron, and coenzymes.
                  </p>
                  <BlogList startIndex={1} title={"Related blog"} />
                  <p className="ps-blog__text">
                    During and after menopause the loss of protective estrogen
                    accelerates bone loss, which can be managed by supplements.
                    Symptoms of menopause like hot flashes, night sweats, and
                    irritability can be managed with multinutrients. What are
                    you waiting for now? Just consult your physician and grab
                    those essential multinutrients to be healthy and happy. Just
                    don't forget to follow a healthy diet and exercise routine.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MultiSupplement;

import React from "react";

import aboutImg2 from "../../assets/img/reason-to-count.jpg";

const ReasonCountRightSide = ({
  image = aboutImg2,
}) => {
    
  return (
    <>
      <section class="ps-section--block-grid row-reverse">
        <div class="ps-section__thumbnail">
          <a class="ps-section__image" href="#">
            <img
              // src="/static/img/about/about-us-2.jpg"
              src={image}
              alt=""
            />
          </a>
        </div>
        <div class="ps-section__content">
          <h3 class="ps-section__title"> Reason To Count On Us </h3>
          {/* <div class="ps-section__subtitle">
                They have CEE 2020 certificate.
              </div> */}
          <div class="ps-section__desc">
            Our proficiency encompasses the realms of pharmaceuticals,
            technology, and regulatory policies, equipping us to bolster an
            array of customer projects. We've united some of the foremost
            experts from these interrelated industries, encompassing technology,
            pharmacy, and customer support, to distinguish our services from the
            rest. Our distinct advantages encompass:
          </div>
          <ul class="ps-section__list">
            <div class="row">
              <div class="col-12">
                <li>A robust delivery system with unparalleled speed.</li>
                <li>24/7 of Availability</li>
                <li>
                  A steadfast commitment to adhering to regulatory policies.
                </li>
                <li>
                  A customer-centric approach designed to maximize their
                  benefits.
                </li>
                <li>
                  A comprehensive solution for everyday and urgent requirements.
                </li>
                <li>
                  A strong emphasis on safeguarding the importance and
                  confidentiality of customer information.
                </li>
              </div>

              {/* <div class="col-md-6 col-12">
                <li>Friday:- 8:00 AM - 10:00 PM</li>
                <li>Saturday:- 8: AM Am - 10:00 PM</li>
                <li>Sunday:- 8: AM Am - 10:00 PM</li>
              </div> */}
            </div>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ReasonCountRightSide;

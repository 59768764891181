import React from "react";

import aboutImg2 from "../../assets/img/mymedi-aboutus.jpg";
import { Link } from "react-router-dom";

export default function HomeAbout() {
  return (
    <>
      <section class="ps-about__project">
        <div class="container">
          <section class="ps-section--block-grid row-reverse pt-5 mt-5">
            <div
              class=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <a class="ps-section__image">
                <img
                  // src="/static/img/about/about-us-2.jpg"
                  src={aboutImg2}
                  alt=""
                />
              </a>
            </div>
            <div class="ps-section__content">
              <h3 class="ps-section__title">Welcome to Drug Point</h3>
              {/* <div class="ps-section__subtitle">
                They have CEE 2020 certificate.
              </div> */}
              <div class="ps-section__desc">
                Drug Point is the emerging private medical retailer in Bihar
                that has been offering high-quality medical services using
                state-of-the-art services in Patna, Bihar, since 2019.
              </div>
              <div class="ps-section__desc">
                We are renowned for the most complex portfolio of medicine and
                healthcare essentials providers: pharmacy, ayurvedic and herbal
                products, health care, personal care, child care, healthy food
                and drinks.
              </div>
              <div class="ps-section__desc">
                Our chain includes 15+ multidisciplinary pharmacies, 10000+
                ideal and trustworthy customers in different cities of Bihar,
                started in the capital of Bihar, Patna at Saguna More, Danapur
                with the blessings of Holy Ganga and Mahavir Hanuman.
              </div>

              <div>
                <Link to={"/our-journey"}>
                  <a class=" ps-btn ps-btn--blue ps-categories__show">
                    Read More
                  </a>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

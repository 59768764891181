import React from "react";

import aboutImg2 from "../../assets/img/about/about-us-2.jpg";
import aboutImg3 from "../../assets/img/about/about-us-3.jpg";
import aboutImg1 from "../../assets/img/about/about-us-1.jpg";

import AboutProjectBlock from "./AboutProjectBlock";

export default function AboutProject() {
  return (
    <>
      <section class="ps-about__project">
        <div class="container">
          <h2 class="ps-about__title">
            Your home medical provider now also online
          </h2>
          <section class="ps-section--block-grid">
            <div class="ps-section__thumbnail">
              <a class="ps-section__image" >
                <img
                  // src="/static/img/about/about-us-1.jpg"
                  src={aboutImg1}
                  alt=""
                />
              </a>
            </div>
            <div class="ps-section__content">
              <h3 class="ps-section__title">
                Many years of experience and a high level of consumer confidence
              </h3>
              <div class="ps-section__subtitle">
                Developed for over 30 years on the market
              </div>
              <div class="ps-section__desc">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered altevration in some form, by
                injected humour, or randomised words which don’t look even
                slightly believable.
              </div>
              <ul class="ps-section__list">
                <li>Proin nec lectus dolor.</li>
                <li>Curabitur egestas molestie lorem sed pharetra.</li>
                <li>Integer volutpat efficitur tellus vel tempus.</li>
              </ul>
            </div>
          </section>
          <section class="ps-section--block-grid row-reverse">
            <div class="ps-section__thumbnail">
              <a class="ps-section__image" >
                <img
                  // src="/static/img/about/about-us-2.jpg"
                  src={aboutImg2}
                  alt=""
                />
              </a>
            </div>
            <div class="ps-section__content">
              <h3 class="ps-section__title">
                Top quality products and proven suppliers with quality
                certificates!
              </h3>
              <div class="ps-section__subtitle">
                They have CEE 2020 certificate.
              </div>
              <div class="ps-section__desc">
                Hundreds of thousands of products at bargain prices. Completely
                the needs of home medicine chest and professional offices.
                Effective and reliable protection for your teeth. The brush
                handle fits perfectly in the hand, is slim and beautifully made.
              </div>
              <ul class="ps-section__list">
                <li>Study history up to 30 days</li>
                <li>Up to 5 users simultaneously</li>
                <li>Has HEALTH certificate</li>
              </ul>
            </div>
          </section>
          <section class="ps-section--block-grid">
            <div class="ps-section__thumbnail">
              <a class="ps-section__image" >
                <img
                  // src="/static/img/about/about-us-3.jpg"
                  src={aboutImg3}
                  alt=""
                />
              </a>
            </div>
            <div class="ps-section__content">
              <h3 class="ps-section__title">
                Top quality products and proven suppliers with quality
                certificates!
              </h3>
              <div class="ps-section__subtitle">
                They have CEE 2020 certificate.
              </div>
              <div class="ps-section__desc">
                Hundreds of thousands of products at bargain prices. Completely
                the needs of home medicine chest and professional offices.
                Effective and reliable protection for your teeth. The brush
                handle fits perfectly in the hand, is slim and beautifully made.
              </div>
              <ul class="ps-section__list">
                <li>Study history up to 30 days</li>
                <li>Up to 5 users simultaneously</li>
                <li>Has HEALTH certificate</li>
              </ul>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

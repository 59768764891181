import React, { useEffect } from "react";
import ErrorComponent from "../component/Error";
import PageHelmet from "../component/common/Helmet";

export default function ErrorPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <React.Fragment>
        <PageHelmet title={"Error"} />
        <ErrorComponent />
      </React.Fragment>
    </>
  );
}

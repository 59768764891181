import React from "react";

import awardImg from "../../assets/img/icon/award-icon-2.png";

import toothbrushImg from "../../assets/img/icon/toothbrush-icon-2.png";

import InfoHeader from "./InfoHeader";
import InfoIconBlock from "./InfoIconBlock";


export default function AboutInfo() {
  return (
    <>
      <section class="ps-about--info">
       <InfoHeader />
        <div class="ps-about__extent">
          <div class="row m-0">
            <div class="col-12 col-md-4 p-0">
              <div class="ps-block--about">
                <div class="ps-block__icon">
                  <img 
                //   src="/static/img/icon/award-icon-2.png" 
                src={awardImg}
                  alt="" />
                </div>
                <h4 class="ps-block__title">
                  Health Certificate 2000 - <br />
                  professional care
                </h4>
                <div class="ps-block__subtitle">
                  The highest quality and protection for your teeth
                </div>
              </div>
            </div>
            <InfoIconBlock />
            
           
            <div class="col-12 col-md-4 p-0">
              <div class="ps-block--about">
                <div class="ps-block__icon">
                  <img 
                //   src="/static/img/icon/toothbrush-icon-2.png" 
                src={toothbrushImg}
                  alt="" />
                </div>
                <h4 class="ps-block__title">
                  3 types <br />
                  of cleaning tips
                </h4>
                <div class="ps-block__subtitle">
                  Round, rectangular and super-wide
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, {useState, useEffect} from "react";

import TopmostHeader from "./TopmostHeader";
import BottomHeader from "./BottomHeader";
import MobileTopHeader from "./MobileTopHeader";

export default function Header() {

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsSticky(window.scrollY > 100)
    })
  }, [])
  
  return (
    <div>
      <header className={isSticky ? "header--desktop header--two header--sticky" : "header--desktop header--two"} id="header-sticky">
        {/* {Start Header topmost bar with social icons } */}
       <TopmostHeader />
        {/* {End Header topmost bar with social icons } */}

        {/* {Start Search Header second topmost bar with search box } */}
          {/* <SearchBoxHeader /> */}
        {/* {End Search Header second topmost bar with search box } */}

        {/* {Start Bottom Header bar } */}
        <BottomHeader />
         {/* {Start Bottom Header bar } */}
       
      </header>

    
      {/* {Start Mobile Header second topmost bar with search box } */}
      <MobileTopHeader />
      {/* {End Mobile Header second topmost bar with search box } */}

  {/* {Start Mobile Header topmost bar with social icons } */}
  {/* <div class="ps-noti header__notice active mobile-only">
        <div class="container">
          <p class="m-0">
            Due to the <strong>COVID 19 </strong>epidemic, orders may be
            processed with a slight delay
          </p>
        </div>
        <a href="#" class="ps-noti__close">
          <i class="fa fa-cross"></i>
        </a>
      </div> */}
      {/* {End Mobile Header topmost bar with social icons } */}

      
    </div>
  );
}

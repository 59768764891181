import React, { useEffect } from "react";

import img1 from "../../assets/img/blog/p5.jpg";
import PageHelmet from "../../component/common/Helmet";
import BlogList from "../../component/BlogList";

const SleepTips = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <PageHelmet title={"Six Tips To Stop Snoring"} />
      <div class="ps-page ps-page--inner ps-page--post">
        <div class="container mt-5 pt-5">
          {/* <BlogDetailHeader /> */}
          <div class="ps-page__content">
            <div class="ps-layout__right">
              <div class="ps-blog">
                <div class="ps-post ps-post--sidebar ps-post--detail">
                  {/* Blog Detail Post Header Start Here */}
                  {/* <BlogDetailPostHeader /> */}
                  <h1 class="ps-post__title">Six Tips To Stop Snoring</h1>
                  {/* Blog Detail Post Header End Here */}

                  {/* Blog Post Banner Starts Here */}
                  <div class="ps-blog__banner">
                    <img
                      src={img1}
                      // src="https://mymedi.reactstorefronts.com/static/img/blog/blog13-992x525.jpg"
                      alt=""
                    />
                  </div>
                  {/* Blog Post Banner Ends Here */}

                  <p class="ps-blog__text-large">
                    Snoring happens when there is an obstruction in the airflow
                    while breathing. The mouth and nasal passage work together
                    when you inhale and exhale. If air flows through the throat
                    instead of the nose when you breathe in your sleep, it
                    causes the relaxed tissues in your throat to vibrate, which
                    leads to harsh, and often irritating sounds.
                  </p>
                  <p class="ps-blog__text">
                    Snoring is a very common condition witnessed in people with
                    excessive weight issues. It is also quite common in
                    advancing age and people who are into the excessive intake
                    of alcohol. It generally troubles men more than women. A
                    health condition like deviated nasal septum or any injury to
                    the nose may also cause snoring. Snoring not only disturbs
                    your sleep or that of your partner it is also not a symptom
                    to ignore. Actually snoring may indicate a few serious
                    health conditions, such as obstructive sleep apnea, obesity,
                    and sleep deprivation. Now let's discuss the Six Tips to
                    stop snoring so that you and your partner can enjoy
                    beautiful and undisrupted sleep-
                  </p>

                  <p class="ps-blog__text-large">
                    {" "}
                    Change Your Sleeping Position-
                  </p>
                  {/* <p class="ps-blog__text-large">Adequate Sleep</p> */}
                  <p class="ps-blog__text">
                    It is just as easy and effective as it sounds. If you are
                    sleeping on your back, it causes the mouth to fall open and
                    you stop breathing effectively through the nose. Sometimes
                    the tongue falls back and obstructs the airway causing
                    snoring. Sleeping on your side is all you need to allow air
                    to flow easily and reduce or stop your snoring.
                  </p>
                  <p class="ps-blog__text-large">Shed The Extra Kilos-</p>
                  <p class="ps-blog__text">
                    If you are overweight or obese, weight loss will help reduce
                    the amount of tissue in the throat. Excess tissue and fat
                    deposition in and around the throat and nose can narrow the
                    passages, making breathing difficult. Stay active and
                    exercise regularly to reduce weight. If your weight gain is
                    due to metabolic reasons such as PCOD and thyroid, consult
                    your doctor to manage your body weight. You should avoid
                    consuming alcohol or sedatives for at least 3 hours before
                    your bedtime. Alcohol usually relaxes the throat muscles,
                    which causes snoring. People sleep deeper after consuming
                    sedatives and alcohol, which can result in airway resistance
                    making them snore louder than sober people
                  </p>

                  <p class="ps-blog__text-large">Don't Be Sleep Deprived-</p>
                  <p class="ps-blog__text">
                    Make sure that you get proper sleep during the night.
                    Researchers recommend 7-9 hours of uninterrupted sleep.
                    Sleep deprivation increases your risk of snoring because it
                    can cause your throat muscles to relax, which makes you more
                    susceptible to airway obstruction. Nasal strips are an easy
                    and non-invasive means to control snoring. Snoring strips
                    are like bandages that can be placed onto the nasal bridge
                    to increase the space in the nasal passage. An external
                    nasal dilator is a stiffened adhesive strip that can be
                    applied on top of your nose across the nostrils to decrease
                    airflow resistance.
                  </p>

                  <BlogList startIndex={1} title={"Related blog"} />
                  <p className="ps-blog__text">
                    If none of the above tips work then you should seek medical
                    help because, in some cases of snoring, you need to address
                    the underlying medical condition responsible for snoring.
                    You should not neglect snoring and identify the cause of
                    snoring at the earliest. Snoring can cause many other
                    complications such as sleep apnea and sleepwalking. With
                    these snoring tips, enjoy a good night's sleep.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SleepTips;

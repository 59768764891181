import React, { useState } from "react";

import { useFormik } from "formik";

import * as Yup from "yup";
import axios from "axios";
import { BACKENED_BASE_URL } from "../../backend-constant";
import Modal from "../Modal/modal";

export default function FranchieForm() {

  const [showModal,setShowModal]=useState(false);


  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const franchiseeSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too short").required("Name is  required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone is required"),
    business: Yup.string().min(2, "Too Short").required("Business is required"),
    state: Yup.string().min(2, "Too Short").required("State is required"),
    district: Yup.string().min(2, "Too Short").required("District is required"),
    city: Yup.string().min(2, "Too Short").required("City is required"),
    years: Yup.number().min(1).required("Years is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      business: "",
      years: "",
      state: "",
      district: "",
      city: "",
    },
    validationSchema: franchiseeSchema,
    onSubmit: (values, { resetForm }) => {
      // setVisible(true);
      // setFormvisibility(false);

      // setAlertMessage("Please wait.");

      setShowModal(true);

      let data = new FormData();
      data.append("name", values.name);
      data.append("email", values.email);
      data.append("phone", values.phone);
      data.append("businessname", values.business);
      data.append("yearinbusiness", values.years);
      data.append("state", values.state);
      data.append("district", values.district);
      data.append("city", values.city);

      let config = {
        method: "post",
        url: `${BACKENED_BASE_URL}franchisee.php`,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // setAlertMessage("Form submitted successfully.");
          //   setVisible(false);

          //   setTimeout(() => setFormvisibility(true), 3000);

          resetForm();
          setShowModal(false);
        })
        .catch((error) => {
          setShowModal(false);
          // setAlertMessage("Something went wrong.");
          // setVisible(false);
          // setTimeout(() => setFormvisibility(true), 3000);
        });

      // alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <div class="ps-form--contact mt-5 pt-5">
        <h2 class="ps-form__title ">Fill up the form for franchise</h2>
        <form onSubmit={formik.handleSubmit}>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
                {formik.errors.name && formik.touched.name ? (
                  <div class="text-danger">{formik.errors.name}</div>
                ) : (
                  <div class="p-0 m-0 text-secondary">Name</div>
                )}
                <input
                  type="text"
                  class="form-control ps-form__input"
                  // placeholder="Name and Surname"
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
                {formik.errors.email && formik.touched.email ? (
                  <div class="text-danger">{formik.errors.email}</div>
                ) : (
                  <div class="p-0 m-0 text-secondary">Email</div>
                )}
                <input
                  type="email"
                  aria-label="email"
                  class="form-control ps-form__input"
                  // placeholder="Your E-mail"
                  name="email"
                  id="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
                {formik.errors.phone && formik.touched.phone ? (
                  <div class="text-danger">{formik.errors.phone}</div>
                ) : (
                  <div class="p-0 m-0 text-secondary">Phone</div>
                )}
                <input
                  // type="text"
                  class="form-control ps-form__input"
                  // placeholder="Phone"
                  type="number"
                  name="phone"
                  id="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
                {formik.errors.business && formik.touched.business ? (
                  <div class="text-danger">{formik.errors.business}</div>
                ) : (
                  <div class="p-0 m-0 text-secondary">Business Name</div>
                )}
                <input
                  type="text"
                  class="form-control ps-form__input"
                  // placeholder="Business Name"
                  name="business"
                  id="business"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.business}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
                {formik.errors.years && formik.touched.years ? (
                  <div class="text-danger">{formik.errors.years}</div>
                ) : (
                  <div class="p-0 m-0 text-secondary">Years in Business</div>
                )}
                <input
                  // type="text"
                  class="form-control ps-form__input"
                  // placeholder="Years in Business"
                  type="number"
                  id="years"
                  name="years"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.years}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
                {formik.errors.state && formik.touched.state ? (
                  <div class="text-danger">{formik.errors.state}</div>
                ) : (
                  <div class="p-0 m-0 text-secondary">State</div>
                )}
                <input
                  type="text"
                  class="form-control ps-form__input"
                  // placeholder="State"
                  name="state"
                  id="state"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
                {formik.errors.district && formik.touched.district ? (
                  <div class="text-danger">{formik.errors.district}</div>
                ) : (
                  <div class="p-0 m-0 text-secondary">District</div>
                )}
                <input
                  type="text"
                  class="form-control ps-form__input"
                  // placeholder="District"
                  name="district"
                  id="district"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.district}
                />
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-form__group">
                {formik.errors.city && formik.touched.city ? (
                  <div class="text-danger">{formik.errors.city}</div>
                ) : (
                  <div class="p-0 m-0 text-secondary">City</div>
                )}
                <input
                  type="text"
                  class="form-control ps-form__input"
                  // placeholder="City"
                  name="city"
                  id="city"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                />
              </div>
            </div>
            {/* <div class="col-12">
            <div class="ps-form__group">
              <textarea
                class="form-control ps-form__textarea"
                rows="5"
                placeholder="Message"
              ></textarea>
            </div>
          </div> */}
          </div>
          <div class="ps-form__submit justify-content-center">
            <button
              type="submit"
              value="submit"
              name="submit"
              id="mc-embedded-subscribe"
              class="ps-btn ps-btn--blue"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <Modal show={showModal} />
    </>
  );
}

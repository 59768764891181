import React from "react";
import logo from "../../assets/img/logo.png";

export default function TopmostHeader() {
  return (
    <>
      <div className="header__topbar">
        <div className="header__topbar-left">
          <div className="container">
            <p>
              <strong>contact@drugpoint.in</strong>
              {"  "}
              "Call: 91+ 95079 55555"
            </p>
            <ul class="ps-list--social header__social-links header__topbar-right">
              <li>
                <a href="https://www.facebook.com/drugpoints/">
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                href="https://www.instagram.com/drug_point_pvt_ltd/"
                >
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                href="#"
                >
                  <i class="fa fa-youtube-play"></i>
                </a>
              </li>
              <li>
                <a
                href="#"
                >
                  <i class="fa fa-pinterest"></i>
                </a>
              </li>
              <li>
                <a
                href="#"
                >
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <div 
          className="header__topbar-right"
          >
            <ul className="menu--topbar">
              <li>
                <a>About</a>
              </li>
              <li>
                <a href="/contact">contact</a>
              </li>
              <li>
                <a href="/shop/order-tracking">Order Tracking</a>
              </li>
              <li>
                <a>Blog</a>
              </li>
            </ul>
           
            <ul class="ps-list--social header__social-links">
              <li>
                <a >
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a >
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a >
                  <i class="fa fa-youtube-play"></i>
                </a>
              </li>
              <li>
                <a >
                  <i class="fa fa-pinterest"></i>
                </a>
              </li>
              <li>
                <a >
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
            <div className="header__switchers">
              <div class="header__switcher">
                <button
                  type="button"
                  class="ant-btn ant-dropdown-trigger ps-dropdown-toggle"
                >
                  <span>English </span>
                  <i class="icon-chevron-down"></i>
                </button>
              </div>
              <div class="header__switcher">
                <button type="button" class="ant-btn ant-dropdown-trigger">
                  <span>USD </span>
                  <i class="icon-chevron-down"></i>
                </button>
              </div>
            </div>
          </div> */}
      </div>
    </>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";


const PageHelmet =({title,description})=>{

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {title}
                     {/* {" | Drug Point"} */}
                </title>
                <meta name="description" content={description} />
             
            </Helmet>

        </React.Fragment>
    )
};

PageHelmet.defaultProps={
    title:"Drug Point",
    description:"",
};

PageHelmet.propTypes={
    title: PropTypes.string,
    description: PropTypes.string
}

export default PageHelmet;


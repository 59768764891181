import React from "react";
import dentistImg from "../../assets/img/icon/dentistry-icon-2.png";

import PropTypes from "prop-types";

 const FranchiseStepBlock=({title,subTitle,image})=> {
  return (
    <>
     
        <div class="col-12 col-md-3 p-0">
          <div
            // class="d-flex justify-content-center align-items-center"
            class="ps-block--about"
          >
            <div class="ps-block__icon">
              <img
                //   src="/static/img/icon/dentistry-icon-2.png"
                src={image}
                alt=""
              />
            </div>
            <h4 class="ps-block__title">
                {title}
              {/* Sonic cleaning <br />
              and whitening power */}
            </h4>
            <div class="ps-block__subtitle">
                {subTitle}
              {/* At the same time, it protects and whitens */}
            </div>
          </div>
        </div>
      
    </>
  );
};

FranchiseStepBlock.defaultProps = {
    title:"Sonic cleaning and whitening power",
    subTitle:"At the same time, it protects and whitens",
    image:dentistImg,
};

FranchiseStepBlock.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    image: PropTypes.string,
}

export default FranchiseStepBlock;

import React from "react";

import maskgroupImg from "../../assets/img/medicine-4.jpg";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function FranchiseFullBanner({title,description,buttonTitle,image}) {
  return (
    <>
      <section
        class="ps-about__banner bg--cover"
        // style="background-image:url(/static/img/Mask-Group.jpg)"
        style={{
            backgroundImage:`url(${image})`
        }}
      >
        <div class="container">
          <div class="ps-banner">
            <h2 class="ps-banner__title">
              {title}
            </h2>
            <div class="ps-banner__desc">
             {description}
            </div>
            <Link
            to={"/franchise"}
            >
            <a class="ps-btn ps-btn--blue" >
             {buttonTitle}
            </a>
            </Link>
          
          </div>
        </div>
      </section>
    </>
  );
};

FranchiseFullBanner.defaultProps={
  image:maskgroupImg,
  title:" Hundreds of thousands of products at bargain prices",
  description:" Complete the needs of home medicine and professional offices, hospitals and clinics.",
  buttonTitle:" Get Franchisee",
};

FranchiseFullBanner.propTypes={
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
};

export default  FranchiseFullBanner;

import React from "react";

import aboutImg3 from "../../assets/img/about-us.jpg";

const JourneyAboutUs = ({ image = aboutImg3 }) => {
  return (
    <>
    <div className="container">
    <section class="ps-section--block-grid mt-5 pt-5">
        <div class="ps-section__thumbnail">
          <a class="ps-section__image" href="#">
            <img
              // src="/static/img/about/about-us-3.jpg"
              src={image}
              alt=""
            />
          </a>
        </div>
        <div class="ps-section__content">
          <h3 class="ps-section__title"> About Us </h3>
          {/* <div class="ps-section__subtitle">
                They have CEE 2020 certificate.
              </div> */}
          <div class="ps-section__desc ">
            Drug Point Private Limited is Bihar's fastest-growing medical
            retailer company was founded in 2019. Our journey began with a
            vision to make healthcare more accessible and convenient for the
            people of Bihar. Since our inception, we marked a rich niche with
            15+ stores and 10000+ satisfied customers, we have been committed to
            providing our customers with high-quality pharmaceuticals and
            healthcare products, ensuring their well-being is our top priority.
          </div>
          <div class="ps-section__desc">
            We take pride in our extensive range of medical supplies,
            prescription medicines, wellness products, and healthcare solutions.
            Our dedicated team of professionals strives to meet the healthcare
            needs of our community with utmost care and reliability. We aim to
            be the trusted partner in your health journey, offering affordable
            and top-notch healthcare options. Join us in our mission to promote
            better health and well-being across Bihar and beyond.
          </div>
        </div>
      </section>
    </div>
     
    </>
  );
};

export default JourneyAboutUs;

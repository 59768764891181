import React from "react";
// import Slider from "react-slick";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./slides.css";

import banner1 from "../../assets/drugpoint/banner/drugpoint-banner-1.jpeg";
import SingleReview from "../SingleReview";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination, Autoplay } from "swiper/modules";


import quoteImg from "../../assets/img/quote-icon.png";
import roundBgImg from "../../assets/img/roundbg.png";

export default function TestimonialCarousel() {
  return (
    <>
      <section
        className="ps-section--reviews ps-testimonials"
        style={{
          backgroundImage: `url(${roundBgImg})`,
        }}
      >
        <h3 className="ps-section__title">
          <img src={quoteImg} alt="quote img" />
          "Latest Reviews"
        </h3>
        <div className="ps-section__content">
          <Swiper
          style={{
            "--swiper-pagination-color": "#103178",
          }}
            loop={true}
            speed={2000}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
          >
            <SwiperSlide>
              <SingleReview image={banner1} />
            </SwiperSlide>
            <SwiperSlide>
              <SingleReview />
            </SwiperSlide>
            <SwiperSlide>
              <SingleReview />
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* <TopBannerTwo image={banner2} line1={"Visit us today and experience the difference"} line2={"Your health and well-being are our priority."} />
            <TopBannerTwo image={banner3} line1={"Stay on Track,"} title2={" Never Miss a Dose!"} line2={"Take the first step towards a better tomorrow."} /> */}
    </>
  );
}

import React from "react";

import promoImg1 from "../assets/img/promotion/bg-banner4.jpg";
import promoImg2 from "../assets/img/promotion/bg-banner5.jpg";

export default function PromoBanner() {
    
  return (
    <>
      <div class="ps-promo ps-promotions ps-home-promotions mt-5">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="ps-promo__item">
                <img
                  class="ps-promo__banner"
                  src={promoImg1}
                //   src="/static/img/promotion/bg-banner4.jpg"
                  alt="alt"
                />
                <div class="ps-promo__content">
                  <span class="ps-promo__badge">New</span>
                  <h4 class="mb-20 ps-promo__name">
                    Get rid of bacteria <br />
                    in your home
                  </h4>
                  <a class="ps-btn ps-btn--blue" href="/shop">
                    More
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ps-promo__item">
                <img
                  class="ps-promo__banner"
                  src={promoImg2}
                //   src="/static/img/promotion/bg-banner5.jpg"
                  alt="alt"
                />
                <div class="ps-promo__content">
                  <h4 class="ps-promo__name">
                    Candid <br />
                    Whitening Kit
                  </h4>
                  <div class="ps-promo__sale">-10%</div>
                  <a class="ps-btn ps-btn--blue" href="/shop">
                    Shop now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

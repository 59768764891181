import React from "react";
import FranchiseStepHeader from "./FranchiseStepHeader";
import FranchiseStepBlock from "./FranchiseStepBlock";

const FranchiseStepSection = () => {
  return (
    <>
      <section class="ps-about--info">
        <FranchiseStepHeader
          title1={""}
          title2={"Grow Your Business With Us"}
          subTitle={
            "Vivamus et felis vitae dolor imperdiet pulvinar id eu dui. Donec ultrices sem nisl, ut porttitor purus scelerisque vel. Morbi eget lacinia ligula, eu condimentum urna. Maecenas id nisi a ex sollicitudin commodo. Duis imperdiet libero eget, Frame Game Optics Limited offers a lucrative franchise model for aspiring entrepreneurs."
          }
        />
        <div class="ps-about__extent">
          <div class="row m-0">
            <FranchiseStepBlock title={"Online Application"} subTitle={""} />
            <FranchiseStepBlock
              title={"Interview And Documentation"}
              subTitle={""}
            />
            <FranchiseStepBlock
              title={"Franchise Agreement Signed & Training"}
              subTitle={""}
            />
            <FranchiseStepBlock
              title={"Site Selection & Store Opening"}
              subTitle={""}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default FranchiseStepSection;

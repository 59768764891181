import React from "react";

import maskgroupImg from "../../assets/img/Mask-Group.jpg";

export default function AboutFullBanner() {
  return (
    <>
      <section
        class="ps-about__banner bg--cover"
        // style="background-image:url(/static/img/Mask-Group.jpg)"
        style={{
            backgroundImage:`url(${maskgroupImg})`
        }}
      >
        <div class="container">
          <div class="ps-banner">
            <h2 class="ps-banner__title">
              Hundreds of thousands of products at bargain prices
            </h2>
            <div class="ps-banner__desc">
              Completely the needs of home medicine chest and professional
              offices
            </div>
            <a class="ps-banner__shop" href="#">
              Shop now
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import awardImg from "../../assets/img/icon/team.png";

import toothbrushImg from "../../assets/img/icon/rating.png";
import drugStore from "../../assets/img/icon/drugstore.png";
import drugCompany from "../../assets/img/icon/pharmaceutical.png";


const WhyChoose =()=>{

    return (
        <>
        <div className="container">
                <div className="ps-about--info mt-5 pt-5">
                  <h2 class="ps-about__title">Why Choose Drug Point</h2>

                  <div className="ps-about__extent">
                    <div class="row m-0 pb-5">

                      <div class="col-12 col-md-3 p-0">
                        <div class="ps-block--about">
                          <div class="ps-block__icon">
                            <img
                              //   src="/static/img/icon/award-icon-2.png"
                              src={awardImg}
                              alt=""
                            />
                          </div>
                          <h4 class="ps-block__title">
                          300+Team
                          </h4>
                          
                        </div>
                      </div>
                     
                      <div class="col-12 col-md-3 p-0">
                        <div class="ps-block--about">
                          <div class="ps-block__icon">
                            <img
                              //   src="/static/img/icon/toothbrush-icon-2.png"
                              src={toothbrushImg}
                              alt=""
                            />
                          </div>
                          <h4 class="ps-block__title">
                          10000+satisfied Customers

                          </h4>
                          
                        </div>
                      </div>

                      <div class="col-12 col-md-3 p-0">
                        <div class="ps-block--about">
                          <div class="ps-block__icon">
                            <img
                              //   src="/static/img/icon/award-icon-2.png"
                              src={drugStore}
                              alt="drug-store"
                            />
                          </div>
                          <h4 class="ps-block__title">
                          15+ Stores
                          </h4>
                          
                        </div>
                      </div>
                     
                      <div class="col-12 col-md-3 p-0">
                        <div class="ps-block--about">
                          <div class="ps-block__icon">
                            <img
                              //   src="/static/img/icon/toothbrush-icon-2.png"
                              src={drugCompany}
                              alt="drug-company"
                            />
                          </div>
                          <h4 class="ps-block__title">
                          Collaboration with 1200+ Companies
                          </h4>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </>
    )
};

export default WhyChoose;


import React from "react";

import aboutImg3 from "../../assets/img/about/about-us-3.jpg";

const LeftImageStore = ({
  image = aboutImg3,
  title = "Drug Point",
  address = "J348+WX9, Bailey Rd, Adarsh Vihar Colony, Rukanpura, Patna, Bihar 801506",
  whatsapp = "9354456637",
  contact = "9354456637",
  email = "drugpoint@gmail.com",
}) => {
  
  return (
    <>
      <section class="ps-section--block-grid">
        <div class="ps-section__thumbnail">
          <a class="ps-section__image" href="#">
            <img
              // src="/static/img/about/about-us-3.jpg"
              src={image}
              alt=""
            />
          </a>
        </div>
        <div class="ps-section__content">
          <h3 class="ps-section__title"> {title} </h3>
          {/* <div class="ps-section__subtitle">
                They have CEE 2020 certificate.
              </div> */}
          <div class="ps-section__desc">Address: {address}</div>
          <ul class="ps-section__list">
            <div class="text-secondary"> Operation Hours: </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <li>Monday:- 8:00 AM - 10:00 PM</li>
                <li>Tuesday:- 8:00 AM - 10:00 PM</li>
                <li>Wednesday:- 8: AM Am - 10:00 PM</li>
                <li>Thursday:- 8:00 AM - 10:00 PM</li>
              </div>
              <div class="col-md-6 col-12">
                <li>Friday:- 8:00 AM - 10:00 PM</li>
                <li>Saturday:- 8: AM Am - 10:00 PM</li>
                <li>Sunday:- 8: AM Am - 10:00 PM</li>
              </div>
            </div>
          </ul>
          <ul class="ps-social">
            <li>
              <a
                class="ps-social__link whatsapp"
                href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
              >
                <i class="fa fa-whatsapp"></i> +91 {whatsapp},{" "}
                <span class="ps-tooltip">WhatsApp</span>
              </a>
            </li>
            <li>
              <a class="ps-social__link linkedin" href={`tel:+91-${contact}`}>
                <i class="fa fa-phone"></i> +91 {contact}
                <span class="ps-tooltip">Phone Call</span>
              </a>
            </li>
            <li>
              <a class="ps-social__link envelope" 
              href={`mailto:${email}`}>
                <i class="fa fa-envelope"></i> {email}
                <span class="ps-tooltip">Email</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default LeftImageStore;

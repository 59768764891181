import React from "react";
import { Helmet } from "react-helmet";
import faqImg from "../../assets/img/welcome-drug-2.jpg";
import WelcomePage from "../OurJourney/WelcomePage";
import ProfileCard2 from "./ProfileCard2";


const OurTeamPage = () => {

    React.useEffect(()=>{
         // Scroll to the top with smooth behavior when the component mounts
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      
    },[]);


    
  return (
    <>
    <Helmet>
        <title>Our Team - Drug Point</title>
    </Helmet>
    <WelcomePage backgroundImage={faqImg} title=""/>
     <div className="container">
      <div className="ps-about--info mt-md-5 pt-md-5 mt-sm-5 pt-sm-5">
        <h2 className="ps-about__title">Our Team</h2>
        {/* <p className="ps-about__subtitle">
          Our mission is to be the leading healthcare solution provider in
          Bihar, delivering accessible and high-quality medical services and
          products to every individual in the community.
        </p> */}

        <div className="row justify-content-center m-0">
          <div className="col-12 col-md-6 col-lg-3 p-0">
            <ProfileCard2
            imageUrl="https://images.unsplash.com/photo-1565464027194-7957a2295fb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80"
            name="Temba Bavuma"
            designation="Software Engineer"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3 p-0">
          <ProfileCard2
            imageUrl="https://images.unsplash.com/photo-1565464027194-7957a2295fb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80"
            name="Temba Bavuma"
            designation="Software Engineer"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3  p-0">
          <ProfileCard2
            imageUrl="https://images.unsplash.com/photo-1565464027194-7957a2295fb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80"
            name="Temba Bavuma"
            designation="Software Engineer"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3  p-0">
          <ProfileCard2
            imageUrl="https://images.unsplash.com/photo-1565464027194-7957a2295fb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80"
            name="Temba Bavuma"
            designation="Software Engineer"
            />
          </div>
        </div>
      </div>
    </div>
    </>
   
  );
};

export default OurTeamPage;

import React, { useEffect } from "react";
import PrivacyFullBanner from "../../component/Privacy/PrivacyFullBanner";
import PrivacyAccordian2 from "../../component/Privacy/PrivacyAccordion2";

import faqImg from "../../assets/img/terms-1.jpg";
import PageHelmet from "../../component/common/Helmet";

const TermsPage=()=>{

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    },[]);

    return (
        <>
        <React.Fragment>
            <PageHelmet title={"Terms & Conditions"} />
         <main className="ps-page ps-page--inner">
        <div className="ps-page__content">
          <div className="ps-about">
            <div className="ps-about__content">
               <PrivacyFullBanner image={faqImg} title={"Terms & Conditions"} />
            </div>
            <div class="container">
            {/* <PrivacyAccordian /> */}
            </div>
            <PrivacyAccordian2 title={"Terms & Conditions"} />
            </div>
            </div>
            </main>

            </React.Fragment>
        </>
    )
};

export default TermsPage;


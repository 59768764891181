// WelcomePage.js

import React, { useState, useEffect, useRef } from "react";
import "./WelcomePage.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";



import { Autoplay } from "swiper/modules";


const WelcomePage = ({
   backgroundImage,
   title= " Welcome to Drug Point "
 }) => {
  const paragraphs = [
    "Drug Point is the emerging private medical retailer in Bihar that has been offering high-quality medical services using state-of-the-art services in Patna, Bihar, since 2019",
    "We are renowned for the most complex portfolio of medicine and healthcare essentials providers: pharmacy, ayurvedic and herbal products, health care, personal care, child care, healthy food and drinks.",
    "Our chain includes 15+ multidisciplinary pharmacies, 10000+ ideal and trustworthy customers in different cities of Bihar, started in the capital of Bihar, Patna at Saguna More, Danapur with the blessings of Holy Ganga and Mahavir Hanuman.",
    "Our team is composed of over 300+ employees, of which 180 are medical professionals.",
    "Our Organization and the quality of our services is NSMCH (Netaji Subhash Chandra Bose Medical College and Hospital) verified. We operate following nationally/internationally renowned medicine protocols in order to guarantee our patients the safety they need.",
    "Our management believes in proactively providing solutions, which has helped us to always be at the forefront and create a niche for ourselves by incorporating essential products into our products and addressing your concerns.",
    "Over the years, we have tried to overcome the rising cost of medicines to many customers through our special offers which enable customers to easily avail medicines at lower prices. By making their demands and needs available at the right price, we have become the reason for their blessings, loyalty and love.",
    "We can offer our Procurement solutions using technologies effectively, cost-effectively and most importantly timely with high level of customer satisfaction due to our state-of-the-art test automation capabilities and infrastructure.",
  ];

 
  return (
    <div
    className="welcome"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // height: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        '@media (max-width: 767px)': {
          height: '100vh', 
        },
      }}
      // ref={containerRef}
      // onWheel={handleScroll}
    >
      <div className="overlay">
        <h1
         
          style={{
            fontWeight: "bold",
            color: "#fff",
            paddingTop:'20px'
          }}
        >
         {title}
        </h1>
       
      </div>
    </div>
  );
};

export default WelcomePage;

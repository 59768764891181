import React from "react";

import paymentImg from "../../../assets/img/payment.png";
import paymentLight from "../../../assets/img/payment-light.png";
import { Link } from "react-router-dom";

const Footer3 = () => {
  return (
    <>
      <footer class="ps-footer ps-footer--3">
        <div class="container">
          <div class="ps-footer__middle">
            {/* Start Footer3 with newsletter block */}
            <div class="row ps-footer__box">
              {/* Start Footer3 adress block 1 */}
              <div class="col-12 col-md-4">
                <div class="ps-footer--contact">
                  <h5 class="ps-footer__title">Need help</h5>
                  <div class="ps-footer__fax">
                    <i class="fa fa-phone"></i>91+ 95079 55555
                  </div>
                  <p class="ps-footer__work">
                    Monday – Friday: 9:00-20:00
                    <br />
                    Saturday: 11:00 – 15:00
                  </p>
                  <hr />
                  <p>
                    <a
                      class="ps-footer__email"
                      href="mailto:contact@drugpoint.in"
                    >
                      {" "}
                      {/* <i class="icon-envelope"></i> */}
                      contact@drugpoint.in{" "}
                    </a>
                  </p>
                </div>
              </div>
              {/* End Footer3 adress block 1 */}

              {/* Start Footer3 adress block 2 */}
              <div class="col-12 col-md-4">
                <div class="ps-footer--address">
                  <a class="ps-logo" href="/">
                    <img src="/static/img/logo.png" alt="logo" />
                  </a>
                  <div class="ps-footer__title">Our store</div>
                  <p>
                    1487 Rocky Horse Carrefour
                    <br />
                    Arlington, TX 16819
                  </p>
                  <p>
                    {/* <a target="_blank" href="/">
                      Show on map
                    </a> */}
                  </p>
                  <ul class="ps-social">
                    <li>
                      <a
                        href="https://www.facebook.com/drugpoints/"
                        class="ps-social__link facebook"
                      >
                        <i class="fa fa-facebook"> </i>
                        <span class="ps-tooltip">Facebook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/drug_point_pvt_ltd/"
                        class="ps-social__link instagram"
                      >
                        <i class="fa fa-instagram"></i>
                        <span class="ps-tooltip">Instagram</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" class="ps-social__link youtube">
                        <i class="fa fa-youtube-play"></i>
                        <span class="ps-tooltip">Youtube</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" class="ps-social__link pinterest">
                        <i class="fa fa-pinterest-p"></i>
                        <span class="ps-tooltip">Pinterest</span>
                      </a>
                    </li>
                    <li>
                      <a href="#" class="ps-social__link linkedin">
                        <i class="fa fa-linkedin"></i>
                        <span class="ps-tooltip">Linkedin</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Footer3 adress block 2 */}

              {/* Start Footer3 adress block 3 */}
              <div class="col-12 col-md-4">
                <div class="ps-footer--newsletter">
                  <h5 class="ps-footer__title">Join our newsletter</h5>
                  <p>And get 20% discount for your first order</p>
                  <form action="do_action" method="post">
                    <div class="ps-form--newsletter">
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control ps-input"
                          placeholder="Enter your email address"
                        />
                        <button class="ps-btn ps-btn--blue">Subscribe</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* End Footer3 adress block 3 */}
            </div>
            {/* End Footer3 with newsletter block */}

            {/* Start Footer3 with footer links block */}
            {/* <div class="ps-footer--listpage ps-footer__links">
              <div class="row">
                <div class="col-4 col-md-2">
                  <ul class="ps-footer__list">
                    <li>
                      <a>About us</a>
                    </li>
                    <li>
                      <a>Delivery information</a>
                    </li>
                    <li>
                      <a>Privacy Policy</a>
                    </li>
                  </ul>
                </div>
                <div class="col-4 col-md-2">
                  <ul class="ps-footer__list">
                    <li>
                      <a>About us</a>
                    </li>
                    <li>
                      <a>Delivery information</a>
                    </li>
                    <li>
                      <a>Privacy Policy</a>
                    </li>
                  </ul>
                </div>
                <div class="col-4 col-md-2">
                  <ul class="ps-footer__list">
                    <li>
                      <a>About us</a>
                    </li>
                    <li>
                      <a>Delivery information</a>
                    </li>
                    <li>
                      <a>Privacy Policy</a>
                    </li>
                  </ul>
                </div>
                <div class="col-4 col-md-2">
                  <ul class="ps-footer__list">
                    <li>
                      <a>About us</a>
                    </li>
                    <li>
                      <a>Delivery information</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            {/* End Footer3 with footer links block */}
          </div>

          {/* Start Footer3 bottom  */}
          <div class="ps-footer--bottom">
            <div class="row">
              <div class="col-12 col-md-6">
                <p>Copyright © 2023 Drug Point. All Rights Reserved</p>
              </div>
              <div class="col-12 col-md-6 text-right">
                <Link to={"/about"}>
                  <a class="pr-4 text-secondary">About</a>
                </Link>
                <Link to={"/franchise"}>
                  <a class="pr-4 text-secondary">Franchisee</a>
                </Link>
                <Link to={"/store"}>
                  <a class="pr-4 text-secondary">Our Store</a>
                </Link>
                <Link to={"/privacy"}>
                  <a class="pr-4 text-secondary">Privacy Policy</a>
                </Link>
                <Link to={"/faq"}>
                  <a class="pr-4 text-secondary">FAQ</a>
                </Link>
                <Link to={"/contact"}>
                  <a class="pr-4 text-secondary">Contact Us</a>
                </Link>

                {/* <img src={paymentImg} alt="" /> */}
                {/* <img
                class="payment-light"
                src={paymentLight}
                alt=""
              /> */}
              </div>
            </div>
          </div>

          {/* End Footer3 bottom  */}
        </div>
      </footer>
    </>
  );
};

export default Footer3;


import React from "react";

const BlogDetailHeader=()=>{

    return (
        <>
        <div class="ps-page__header">
            <ul class="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li>
                The latest tests of popular masks in accordance with CV2s
                standards
              </li>
            </ul>
          </div>
        </>
    )
};

export default BlogDetailHeader;

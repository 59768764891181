import React from "react";
import { Link } from "react-router-dom";

import quoteIcon from "../assets/img/quote-icon.png";

import PropTypes from "prop-types";

function SingleReview({ title, image, paragraph }) {
  return (
    <>
      <div class="col-12">
        <article class="ps-post ">
         
          <div class="ps-post__wrapper container  pl-4 pr-4 ">
            <div class="ps-post__content text-center ">
              <div class="ps-review col justify-content-center">
               
                <div class="ps-review__text">
                  {paragraph}
                </div>

                <img
                  class="rounded-circle shadow mb-2 "
                  style={{ width: "100px", height: "100px" }}
                  alt="avatar2"
                  src={image}
                />

                <div class="ps-review__name">{title}</div>

                <div class="ps-review__name">
                  <span class="ps-rating">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star-o"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

SingleReview.defaultProps = {
  image:"https://mdbcdn.b-cdn.net/img/new/avatars/9.webp",
  title: "Category one",
  paragraph:
    "The standard chunk of Lorem Ipsum used since the I ordered on Friday evening and on Monday at 12:30 the package was with me. 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from de Finibus Bonorum et Malorum by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.",
};

SingleReview.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  paragraph: PropTypes.string,
};

export default SingleReview;

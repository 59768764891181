import React from "react";

import PropTypes from "prop-types";


import bannerFour from "../assets/img/promotion/slide2.jpg";

 function TopBannerTwo({image,line1,line2,title2}) {
  return (
    <>
      <div class="ps-home  pb-20 pt-50 pt-lg-0 mt-0 ">
        <div class="ps-home__banner">
          <div
            class="ps-banner"
            //   style="background:#103187"
            style={{
              background: "#103187",
            }}
          >
            <img
              class="ps-banner__overlay"
              //   src="/static/img/promotion/home4-banner-4.jpg"
              src={image}
              alt="alt"
            />
            <div class="ps-banner__block pt-lg-5">
              <div class="ps-banner__content  pt-5">
                <h2 class="ps-banner__title text-white  pt-lg-5">
                 {line1} <br />
                 {title2}
                 {/* {line2} */}
                </h2>
                <div class="ps-banner__desc text-white">
                  {line2}
                </div>
                {/* <div class="ps-banner__price">
                  <span class="text-yellow">$25.99</span>
                  <del>$15.99</del>
                </div> */}
                {/* <a class="ps-btn ps-btn--warning" >
                 Get Franchise
                </a> */}
              </div>
              {/* <div class="ps-banner__thumnail">
                <div class="ps-banner__persen bg-yellow">-30%</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TopBannerTwo.defaultProps={
  image:bannerFour,
  title2:"",
  line1:"Welcome To Drug Point",
  line2:"Your one stop destination for all your health and wellness needs"
};

TopBannerTwo.propTypes={
  image:PropTypes.string,
  title2:PropTypes.string,
  line1:PropTypes.string,
  line2:PropTypes.string,
}

export default TopBannerTwo;

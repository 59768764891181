import React from "react";

import "./accordion2.scss";
import AccordionTab2 from "./AccordionTab2";

import PropTypes from "prop-types";


const PrivacyAccordian2=({title})=> {
  return (
    <>
      <div class="container mt-5">
        <h2 class="text-center">{title}</h2>
        <div class="accordion">
          <AccordionTab2 />
          <AccordionTab2
            buttonId="accordion-button-2"
            title={"Why is the sky blue?"}
          />
          <AccordionTab2
            buttonId="accordion-button-3"
            title={"Will we ever discover aliens?"}
          />
           <AccordionTab2
            buttonId="accordion-button-2"
            title={"Why is the sky blue?"}
          />
          <AccordionTab2
            buttonId="accordion-button-3"
            title={"Will we ever discover aliens?"}
          />
        </div>
      </div>
    </>
  );
};

PrivacyAccordian2.defaultProps={
  title:"Privacy Policy",
};

PrivacyAccordian2.propTypes = {
  title: PropTypes.string,
}

export default PrivacyAccordian2;

import React from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination, Autoplay, Navigation } from "swiper/modules";

import "./customswiper.css";

SwiperCore.use([Navigation]);

const storeLocations = [
  {
    id: "rukanpura",
    title: "Rukanpura, Patna",
  },
  {
    id: "saguna-more",
    title: "Saguna More, Patna",
  },
  {
    id: "patliputra",
    title: "Patliputra, Patna",
  },
  {
    id: "raxaul",
    title: "Raxaul",
  },
  {
    id: "begusarai",
    title: "Begusarai",
  },
  {
    id: "bettiah",
    title: "Bettiah",
  },

  {
    id: "sk-puri",
    title: "SK Puri, Patna",
  },
  {
    id: "kanti-factory",
    title: "Kanti Factory, Patna",
  },
  {
    id: "sitamarahi",
    title: "Sitamarahi",
  },
  {
    id: "gaya",
    title: "Gaya",
  },
  {
    id: "koriya-tola-raxaul",
    title: "Koriya Tola, Raxaul",
  },
  {
    id: "anishabad",
    title: "Anishabad, Patna",
  },
  {
    id: "bihta",
    title: "Bihta, Patna",
  },
];

const StoreLocation = () => {
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    
          <div className="row m-0">
            <Swiper
              navigation={true}
              slidesPerView={1}
              spaceBetween={0}
              loop={true}
              modules={[Navigation,Autoplay]}
              // centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 7,
                  spaceBetween:2,
                },
              }}
            >
              {storeLocations.map((item) => (
                <SwiperSlide
                key={item.id}
                >
                  <div
                    // className="ps-block--about"
                    style={{
                      // backgroundColor: "#fff4e6",
                      margin: 0,
                      textAlign:'flex',
                      // fontWeight:'bold',
                      padding:0,
                      // border:'1px solid #854442'
                    }}
                  >
                    <a
                      key={item.id}
                      onClick={() => {
                        scrollToSection(item.id);
                      }}
                      // className="text-center"
                      style={{
                        // fontWeight:'bold',
                        color:"#4b3832",
                        textTransform:'uppercase'
                      }}
                    >
                      {item.title}
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div ref={prevRef} className="swiper-button-prev">
              Previous
            </div>
            <div ref={nextRef} className="swiper-button-next">
              Next
            </div>
            {/* Items start here */}
          </div>
         
      
  );
};

export default StoreLocation;

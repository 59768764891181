import React, { useEffect } from "react";

import img1 from "../../assets/img/blog/p3.jpg";
import PageHelmet from "../../component/common/Helmet";
import BlogList from "../../component/BlogList";

const CareHeart = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <React.Fragment>
      <PageHelmet title={" Taking Care Of Your Heart In Winter"} />
      <div class="ps-page ps-page--inner ps-page--post">
        <div class="container mt-5 pt-5">
          {/* <BlogDetailHeader /> */}
          <div class="ps-page__content">
            <div class="ps-layout__right">
              <div class="ps-blog">
                <div class="ps-post ps-post--sidebar ps-post--detail">
                  {/* Blog Detail Post Header Start Here */}
                  {/* <BlogDetailPostHeader /> */}
                  <h1 class="ps-post__title">
                    Taking Care Of Your Heart In Winter
                  </h1>
                  {/* Blog Detail Post Header End Here */}

                  {/* Blog Post Banner Starts Here */}
                  <div class="ps-blog__banner">
                    <img
                      src={img1}
                      // src="https://mymedi.reactstorefronts.com/static/img/blog/blog13-992x525.jpg"
                      alt=""
                    />
                  </div>
                  {/* Blog Post Banner Ends Here */}

                  <p class="ps-blog__text-large">
                    The chilly, foggy, and salubrious winter season is here. We
                    all adore this season as we get to enjoy hot snacks, sweets,
                    and chai with Gupshup in sun. But this lovely season is
                    difficult for our heart as its vulnerability increases
                    during the winter season. Incidents of casualties due to
                    heart disease and stroke are more common in winter.
                    According to studies heart attacks are fatal in winter
                    compared to summer. We should know how winter can affect our
                    heart health so that a heart attack can be prevented.
                  </p>
                  <p className="ps-blog__text-large">
                    What Happens To Your Heart In Winter?
                  </p>
                  <p class="ps-blog__text">
                    In the winter season, the human body undergoes a few
                    physiological and biological changes due to various factors
                    like low temperature, air pressure, wind, and humidity. As a
                    result, your blood vessels and coronary arteries can
                    constrict, followed by increased blood pressure, restrictive
                    blood flow, and a reduction in the oxygen supply to the
                    heart, which can lead to a heart attack. Along with that,
                    your heart has to work harder in winter to maintain an
                    optimum body temperature. Heart muscle can also be damaged
                    by a condition called hypothermia in which the body loses
                    heat faster than it is producing.
                  </p>

                  <p class="ps-blog__text-large">
                    Who Are At Risk Of A Heart Attack In Winter?
                  </p>
                  {/* <p class="ps-blog__text-large">Adequate Sleep</p> */}
                  <p class="ps-blog__text">
                    The following categories of people are at a high risk of a
                    heart attack- Having a prior history of heart problems
                    Suffered a heart attack previously Having high blood
                    pressure and high cholesterol Heavy drinkers and smokers
                    Leading a sedentary lifestyle
                  </p>
                  <p class="ps-blog__text-large">
                    How To Avoid Winter Heart Attacks?
                  </p>
                  <p class="ps-blog__text">
                    Generally, people quickly dismiss signs of a heart attack as
                    indigestion or a muscle strain and ignore the need to
                    consult a doctor. Do not ignore these signs and follow these
                    preventive measures to keep your heart healthy Avoid
                    excessive alcohol consumption and smoking. Stay warm and
                    cover yourself with a sufficient layer of clothes. Exercise
                    regularly but bring it indoors rather than doing it
                    outdoors. Manage your stress by engaging in your favorite
                    hobbies and yoga and meditation. Regular health checkups are
                    a must. Eat a healthy and nutritious diet and avoid spicy,
                    fatty, and fried food. Do not over-exert yourself. Avoid
                    strenuous work and take regular intervals especially so if
                    you have a history of heart disease.
                  </p>

                  <p class="ps-blog__text-large">What Signs To Watch Out?</p>
                  <p class="ps-blog__text">
                    Discomfort or pain in the chest. Heartburn, indigestion, and
                    stomach pain. Wheezing or shortening of breath. Nausea or
                    dizziness. Sweating without reason. Feeling tired
                    frequently.
                  </p>

                  <BlogList startIndex={3} title={"Related blog"} />
                  <p className="ps-blog__text">
                    The winter season can be difficult for your heart. But if
                    you follow a healthy lifestyle you can make your heart enjoy
                    this lovely weather. Most importantly do not ignore any of
                    the above symptoms and immediately take counsel from your
                    doctor. Any delay in diagnosis and treatment of these
                    symptoms can be fatal and increase the risk of
                    complications. So take good care of your heart and let it
                    spread love and happiness over a cup of tea in wonderful
                    winters.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CareHeart;

import React from "react";

import aboutImg2 from "../../assets/img/about/about-us-2.jpg";
import aboutImg3 from "../../assets/img/about/about-us-3.jpg";
import AboutProjectBlock from "../../component/About/AboutProjectBlock";
import LeftImageStore from "./LeftImageStore";
import RightImageStore from "./RightImageStore";
import StoreLocation from "./StoreLocation";
import TabComponent from "./TabComponent";
import StoreLocationSlider from "../../component/Slider/StoreLocationSlider";


const tabData = ['Tab 1', 'Tab 2', 'Tab 3', 'Tab 4', 'Tab 5', 'Tab 6','Tab 1', 'Tab 2', 'Tab 3', 'Tab 4', 'Tab 5', 'Tab 6'];

export default function OurStores() {
  
  
  return (
    <>
      <section class="ps-about__project">
        <div class="container">
         
          <TabComponent  />
          {/* <StoreLocationSlider /> */}
        {/* <StoreLocation /> */}
          <h2 class="ps-about__title">
            {/* Your home medical provider now also online */}
          </h2>
          {/* <AboutProjectBlock /> */}

         
          <div id="rukanpura">
            <LeftImageStore
              title="Drug Point - Rukanpura"
              contact="7079071790"
              whatsapp="7079071790"
              address="Rukanpura-Bailey Road, Near Sai Yamaha Showroom - 800014"
            />
          </div>
          <div id="saguna-more">
            <RightImageStore
              title="Drug Point - Saguna More"
              contact="9709181111"
              whatsapp="9709181111"
              address="Saguna More, Danapur - 801503"
            />
          </div>
          <div id="patliputra">
            <LeftImageStore
              title="Drug Point - Patliputra"
              contact="7979708327"
              whatsapp="7979708327"
              address="Plot No - 39A, Near ICICI Bank, Patliputra,800013"
            />
          </div>
          <div id="raxaul">
            <RightImageStore
              title="Drug Point - Raxaul"
              contact="9430094400"
              whatsapp="9430094400"
              address="PV Mall, Main Road, Near Bata Showroom, Raxaul - Bihar - 845305"
            />
          </div>
          <div id="begusarai">
            <LeftImageStore
              title="Drug Point - Begusarai"
              contact="9122659715"
              whatsapp="9122659715"
              address="Jaya Complex, Dak Bunglow Road, Opp SK Mahila College Begusarai -851101"
            />
          </div>

          <div id="bettiah">
            <RightImageStore
              title="Drug Point - Bettiah"
              contact="9234766648"
              whatsapp="9234766648"
              address="Not Available"
            />
          </div>

          <div id="kanti-factory">
            <LeftImageStore
              title="Drug Point - Kanti Factory "
              contact="7292932455"
              whatsapp="7292932455"
              address="Kanti Factory Road, Kankarbagh, Patna Tal - 800020"
            />
          </div>

          <div id="sk-puri">
            <RightImageStore
              title="Drug Point - SK Puri"
              contact="9709285555"
              whatsapp="9709285555"
              address="218, A, S.K Puri, Patna Tall - 800001"
            />
          </div>
        
          <div id="sitamarahi">
            <LeftImageStore
              title="Drug Point - Sitamarahi "
              contact="8407029999/06226-358228"
              whatsapp="8407029999/06226-358228"
              address="Dumra Road, Rajopatti, Sitamarahi, Bihar - 843302"
            />
          </div>
          <div id="gaya">
            <RightImageStore
              title="Drug Point - Gaya"
              contact="9801221922"
              whatsapp="9801221922"
              address="Ground Floor, Opp. Gaya Club, White House Compound, Near Big Bazar, A.P. Colony, Gaya - 823001"
            />
          </div>
          <div id="koriya-tola-raxaul">
            <LeftImageStore
              title="Drug Point - Raxaul "
              contact="7903323766"
              whatsapp="7903323766"
              address="Mateshwati Complex, Koriya Tola, Raxaul, East Champaran - 845305"
            />
          </div>
          <div id="anishabad">
            <RightImageStore
              title="Drug Point - Anishabad"
              contact="7079071790"
              whatsapp="7079071790"
              address="Anishabad, Patna"
            />
          </div>
          <div id="bihta">
            <LeftImageStore
              title="Drug Point - Bihta "
              contact="9263879462"
              whatsapp="9263879462"
              address="Nsmch drug point
            Amhara bihta patna 
            801103"
            />
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "./photos";
import { Helmet } from "react-helmet";

import glleryImg from "../../assets/img/gallery.jpg";
import WelcomePage from "../OurJourney/WelcomePage";


const GalleryPage = () => {
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

  const openLightbox = React.useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  React.useEffect(() => {

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
  }, []);

  return (
    <>
    <Helmet>
      <title>
        Gallery - Drug Point
      </title>
    </Helmet>

     <React.Fragment>
      <WelcomePage
      backgroundImage={glleryImg}
      title="Gallery"
      />
      <div className="container mt-5 pt-sm-5">
        <section className="ps-about--info pt-5">
          <h2 className="ps-about__title">Gallery</h2>
          <div>
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title,
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        </section>
      </div>
    </React.Fragment>
    </>
   
  );
};

export default GalleryPage;

import React from "react";

const BlogDetailPostRowReview = () => {
  return (
    <>
      <div class="row ps-blog__row">
        <div class="col-12 col-md-6">
          <div class="ps-review">
            <div class="ps-review__text">
              There was a small mistake in the order. In return, I got the
              correct order and I could keep the wrong one for myself.
            </div>
            <div class="ps-review__name">Esther Howard</div>
            <div class="ps-review__review">
              <span class="ps-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star-o"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="ps-review">
            <div class="ps-review__text">
              I ordered on Friday evening and on Monday at 12:30 the package was
              with me. I have never encountered such a fast order processing.
            </div>
            <div class="ps-review__name">Albert Flores</div>
            <div class="ps-review__review">
              <span class="ps-rating">
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star"></i>
                <i class="fa fa-star-o"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailPostRowReview;

import img1 from "../../assets/img/blog/p1.jpg";
import img2 from "../../assets/img/blog/p2.jpg";
import img3 from "../../assets/img/blog/p3.jpg";
import img4 from "../../assets/img/blog/p4.jpg";
import img5 from "../../assets/img/blog/p5.jpg";
import img6 from "../../assets/img/blog/p6.jpg";


export const blogData=[
    {
        image:img1,
        category:"immune-improve",
        title:"Improving Your Immunity",
        paragraph:"The immune system is the first line of defense against illness in your body. It is just like a security system around your house for protection.",
    },
   
    {
        image:img2,
        category:"multi-supplement",
        title:"Benefits Of Multi-Nutrient Supplements",
        paragraph:"Multivitamins and Multi minerals are the most commonly used health supplements in the world.",
    },
    {
        image:img3,
        category:"care-heart",
        title:"Taking Care Of Your Heart In Winter",
        paragraph:"The chilly, foggy, and salubrious winter season is here. We all adore this season as we get to enjoy hot snacks, sweets, and chai with Gupshup in sun."
    },
    {
        image:img4,
        category:"bone-care",
        title:"Joint And Bone Care",
        paragraph:"The proper care of bones and joints is of utmost importance. Bones play many roles in the body, like providing structure, protecting organs, and storing calcium",
    },
    {
        image:img5,
        category:"sleep-tips",
        title:"Six Tips To Stop Snoring",
        paragraph:"Snoring happens when there is an obstruction in the airflow while breathing. The mouth and nasal passage work together when you inhale and exhale.",
    },
    {
        image:img6,
        category:"release-stress",
        title:"Simple Steps To Soothe Stress",
        paragraph:"The hectic lifestyle and the fast-paced world in which we are living today often leave us stressed and anxious.",
    },
   
  
]

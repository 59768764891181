

import cat1 from "../../assets/drugpoint/drug/ayurvedic.jpg";
import cat2 from "../../assets/drugpoint/drug/baby-care.webp";
import cat3 from "../../assets/drugpoint/drug/hair-care.jpg";
import cat4 from "../../assets/drugpoint/drug/healthcare-device.png";
import cat5 from "../../assets/drugpoint/drug/skincare.jpg";
import cat6 from "../../assets/drugpoint/drug/sanitizer.jpg";
import cat7 from "../../assets/drugpoint/drug/diabetic-care.jpg";
import cat8 from "../../assets/drugpoint/drug/vitamin-supliment.jpg";

// import img1 from "../../assets"

export const photos = [
    {
        src: cat1,
        width: 4,
        height: 3
      },
      {
        src: cat2,
        width: 1,
        height: 1
      },
      {
        src: cat3,
        width: 3,
        height: 4
      },
      {
        src: cat4,
        width: 3,
        height: 4
      },
      {
        src: cat5,
        width: 3,
        height: 4
      },
    {
      src: cat6,
      width: 4,
      height: 3
    },
    {
      src: cat7,
      width: 1,
      height: 1
    },
    {
      src: cat8,
      width: 3,
      height: 4
    },
    {
      src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
      width: 3,
      height: 4
    },
    {
      src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
      width: 3,
      height: 4
    },
    {
      src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
      width: 3,
      height: 4
    },
    {
      src: "https://source.unsplash.com/PpOHJezOalU/800x599",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
      width: 4,
      height: 3
    },
  ];
  
import React from "react";
import PropTypes from "prop-types";

import featuredImg1 from "../../assets/img/promotion/bg-banner6.jpg";

const SingleFeatureProduct = ({ img,text_class }) => {
  return (
    <>
      <div class="col-12 col-md-4 mb-5">
        <div class="ps-promo__item">
          <img
            class="ps-promo__banner"
            //   src="/static/img/promotion/bg-banner6.jpg"
            src={img}
            alt="alt"
          />
          {/* <div class="ps-promo__content">
            <h4 class={"ps-promo__name "+(text_class)}>
              Get rid <br />
              of bacteria <br />
              in your home
            </h4>
            <div class={"ps-promo__sale text-primary "+(text_class)}>-7%</div>
            <a class="ps-promo__btn" href="/shop">
              Shop now
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

SingleFeatureProduct.defaultProps = {
  img: featuredImg1,
  text_class:" "
};

SingleFeatureProduct.propTypes = {
  img: PropTypes.string,
  text_class: PropTypes.string,
};

export default SingleFeatureProduct;

import React from "react";

import logoImg from "../assets/img/logo.png";
import virusIcon from "../assets/img/icon/virus.svg";
import ribbonIcon from "../assets/img/icon/ribbon.svg";
import round5 from "../assets/drugpoint/banner/bg-banner-image.jpg";
import girlImg from "../assets/drugpoint/banner/111-removebg-preview.png";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SingleBanner = ({ logoImg, virusIcon, ribbonIcon, round5, girlImg }) => {
  return (
    <>
      <div class="ps-home-banner-promotion container pt-50 pb-50 ">
        <div class="ps-banner--round ps-banner--promotion">
          <div class="container">
            <div class="ps-banner">
              <div class="ps-banner__block">
                <div class="ps-banner__content">
                  <div class="ps-logo">
                    <a href="/">
                      <img
                        //   src="/static/img/logo.png"
                        src={logoImg}
                        alt=""
                      />
                    </a>
                  </div>
                  <h2 class="ps-banner__title">
                    We provide comprehensive training and ongoing support,
                    equipping you with the knowledge and skills to excel in
                    managing your own pharmacy store."
                  </h2>
                  {/* <div class="ps-banner__btn-group">
                    <div class="ps-banner__btn">
                      <img 
                    //   src="/static/img/icon/virus.svg"
                      src={virusIcon}
                       alt="" />
                      Up to 5 users simultaneously
                    </div>
                    <div class="ps-banner__btn">
                      <img 
                    //   src="/static/img/icon/ribbon.svg"
                    src={ribbonIcon}
                       alt="" />
                      Has HEALTH certificate
                    </div>
                  </div> */}
                  <Link
                  to={"/franchise"}
                  >
                  <a 
                  class="ps-btn ps-btn--blue" 
                  // href="#"
                  >
                    Get Franchise
                  </a>
                  
                  </Link>
                 
                </div>
                <div class="ps-banner__thumnail">
                  <img
                    class="ps-banner__round"
                    // src="/static/img/round5.png"
                    // src={round5}
                    alt=""
                  />
                  <img
                    class="ps-banner__image"
                    // src="/static/img/girl-face-mask.png"
                    src={girlImg}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SingleBanner.defaultProps = {
  logoImg: logoImg,
  virusIcon: virusIcon,
  ribbonIcon: ribbonIcon,
  round5: round5,
  girlImg: girlImg,
};

SingleBanner.propTypes = {
  logoImg: PropTypes.string,
  virusIcon: PropTypes.string,
  ribbonIcon: PropTypes.string,
  round5: PropTypes.string,
  girlImg: PropTypes.string,
};

export default SingleBanner;

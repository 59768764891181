import React from "react";
import logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";
import "./header-dropdown.css";

export default function BottomHeader() {
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTextHover = () => {
    setIsDropdownVisible(true);
  };

  const handleDropdownClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <div class="header__bottom active ">
        <nav class="navigation--single">
          <div class="container">
            <Link to={"/"}>
              <a class="ps-logo pr-5">
                <img src={logo} alt="logo" />
              </a>
            </Link>

            <div class="navigation__menu ">
              <ul class="menu menu--desktop ">
                <li class="menu-item-has-children has-mega-menu">
                  <Link to={"/"}>
                    <a>Home</a>
                  </Link>
                </li>

                <li>
                  <div className="dropdown-container">
                    <a
                      className="hover-text"
                      onMouseEnter={handleTextHover}
                      onClick={handleDropdownClick}
                      style={{
                        color: "#103178",
                        fontWeight: "normal",
                        "&hover": {
                          color: "#fa7e1e",
                        },
                      }}
                    >
                      About Us{" "}
                      {isDropdownVisible ? (
                        <i class="fa fa-angle-up"></i>
                      ) : (
                        <i class="fa fa-angle-down"></i>
                      )}
                    </a>
                    {isDropdownVisible && (
                      <div ref={dropdownRef} className="dropdown-content">
                        <ul>
                          <li>
                            <Link to={"/our-journey"}>
                             
                              <a
                                onClick={handleDropdownClick}
                                style={{
                                  color: "#103178",
                                  // marginBottom:'10px',
                                }}
                              >
                                Our Journey
                              </a>
                            </Link>
                          </li>
                          <li>
                            <Link to={"/our-team"}>
                             
                              <a
                                onClick={handleDropdownClick}
                                style={{
                                  color: "#103178",
                                }}
                              >
                                Our Team
                              </a>
                            </Link>
                          </li>
                          <li>
                            <Link
                            to={"/founder-desk"}
                            >
                            <a
                              onClick={handleDropdownClick}
                              style={{
                                color: "#103178",
                              }}
                            >
                              Founder’s Desk
                            </a>
                            </Link>
                           
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>

                {/* <li>
                  <Link to={"/about"}>
                    <a>About Us</a>
                  </Link>
                </li> */}

                {/* <li class="menu-item-has-children has-mega-menu">
                  <Link to={"/franchise"}>
                    <a>Franchisee</a>
                  </Link>
                </li> */}
                {/* <li>
                
                  <a>Products</a>
                </li> */}

                <li>
                  <Link to={"/store"}>
                    <a>Our Stores</a>
                  </Link>
                </li>

                <li>
                  <Link to={"/blog"}>
                    <a>Our Blog</a>
                  </Link>
                </li>

                <li>
                  <Link to={"/gallery"}>
                    <a>Gallery</a>
                  </Link>
                </li>
                <li>
                  <Link to={"/career"}>
                    <a>Career</a>
                  </Link>
                </li>
                <li>
                  <Link to={"/contact"}>
                    <a>Contact Us</a>
                  </Link>
                </li>
                <li>
                  <Link to={"/franchise"}>
                    <button class="ps-btn ps-btn--blue">
                      Get Franchisee
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

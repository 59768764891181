import React from "react";
import PropTypes from "prop-types";

import bannerFour from "../../assets/img/privacy-1.jpg";

const PrivacyFullBanner =({image, title,subTitle})=> {
  return (
    <>
      <div class="ps-home container pb-20 pt-50 pt-lg-0 mt-3 mt-xl-2">
        <div class="ps-home__banner">
          <div
            class="ps-banner"
            //   style="background:#103187"
            style={{
              background: "#103187",
            }}
          >
            <img
              class="ps-banner__overlay"
              //   src="/static/img/promotion/home4-banner-4.jpg"
              src={image}
              alt="alt"
            />
            <div class="ps-banner__block">
              <div class="ps-banner__content">
                <h2 class="ps-banner__title text-white">
                  {title}
                  {/* PowerSteel <br />
                  X-tra 200 Brush */}
                </h2>
                <p class="ps-blog__text text-white">
                 {subTitle}
                </p>
                {/* <div class="ps-banner__price">
                  <span class="text-yellow">$25.99</span>
                  <del>$15.99</del>
                </div> */}
                {/* <a class="bg-yellow ps-banner__shop" href="#">
                  Add to cart
                </a> */}
              </div>
              {/* <div class="ps-banner__thumnail">
                <div class="ps-banner__persen bg-yellow">-30%</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PrivacyFullBanner.defaultProps={
    image: bannerFour,
    title:"Privacy Policy",
    subTitle:" Vivamus et felis vitae dolor imperdiet pulvinar id eu dui. Donec ultrices sem nisl, ut porttitor purus scelerisque vel. Morbi eget lacinia ligula, eu condimentum urna."
};

PrivacyFullBanner.propTypes={
    image: PropTypes.string,
    title:PropTypes.string,
    subTitle:PropTypes.string,
}

export default PrivacyFullBanner

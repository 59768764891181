import ContactForm from "../../component/Contact/ContactForm";
import doctorImg from "../../assets/img/enquiry-medical.jpg";

import React, { useEffect } from "react";
import PageHelmet from "../../component/common/Helmet";

const EnquiryPage = () => {

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    
  },[]);

  return (
    <>
    <React.Fragment>

   <PageHelmet title={"Enquiry "} />
      <div className="ps-page ps-page--inner ps-page--notfound">
        <div className="container">
          <div class="row mb-80 mt-80">
            <div class="col-12  col-lg-8">
              <ContactForm title={"Enquiry"} />
            </div>
            <div class="col-12  col-lg-4">
              <img
                //   src="/static/img/cartoon-doctor.jpg"
                src={doctorImg}
                alt="cartoon-doctor"
              />
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    </>
  );
};

export default EnquiryPage;

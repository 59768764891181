import React from "react";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { Outlet } from "react-router-dom";
import Footer3 from "./component/Footer/Footer3/Footer3";


export default function Layout() {
  return (
    <div className="loaded ps-loaded">
      <div className="ps-page">
        <div className="ps-root">
          <Header />
          {/* Here lies other all pages within outlet */}
          <Outlet />
          {/* <Footer /> */}
          <Footer3 />
          </div>
      </div>
    </div>
  );
}

import React from "react";

import logoImg from "../../assets/img/logo.png";
import virusIcon from "../../assets/img/icon/virus.svg";
import ribbonIcon from "../../assets/img/icon/ribbon.svg";
import round5 from "../../assets/img/round5.png";
import girlImg from "../../assets/img/franchise-1-removebg-preview.png";

import PropTypes from "prop-types";
import FranchiseBenefitsButtonGroup from "./FranchiseBenefitsButtonGroup";

const FranchiseSingleBanner = ({
  logoImg,
  virusIcon,
  ribbonIcon,
  round5,
  girlImg,
}) => {
  return (
    <>
      <div class="ps-home-banner-promotion container pt-50 pb-50 ">
        <div class="ps-banner--round ps-banner--promotion">
          <div class="container">
            <div class="ps-banner">
              <div class="ps-banner__block">
                <div class="ps-banner__content">
                  <div class="ps-logo">
                    <a href="/">
                      <img
                        //   src="/static/img/logo.png"
                        src={logoImg}
                        alt=""
                      />
                    </a>
                  </div>
                  <h2 class="ps-post__title">
                    Franchise Benefits <br />
                    {/* online pharmacy! */}
                  </h2>
                  {/* <FranchiseBenefitsButtonGroup />
                  <FranchiseBenefitsButtonGroup />
                  <FranchiseBenefitsButtonGroup />
                  <FranchiseBenefitsButtonGroup />
                  <FranchiseBenefitsButtonGroup />
                  <FranchiseBenefitsButtonGroup /> */}
                  <p class="ps-blog__text text-secondary">
                    Vivamus et felis vitae dolor imperdiet pulvinar id eu dui.
                    Donec ultrices sem nisl, ut porttitor purus scelerisque vel.
                    Morbi eget lacinia ligula, eu condimentum urna. Maecenas id
                    nisi a ex sollicitudin commodo. Duis imperdiet libero eget
                   
                  </p>
                  <p class="ps-blog__text text-secondary">
                    Vivamus et felis vitae dolor imperdiet pulvinar id eu dui.
                    Donec ultrices sem nisl, ut porttitor purus scelerisque vel.
                    Morbi eget lacinia ligula, eu condimentum urna. Maecenas id
                    nisi a ex sollicitudin commodo. Duis imperdiet libero eget
                   
                  </p>

                  {/* <a class="ps-banner__shop bg-yellow" href="#">
                    About
                  </a> */}
                </div>
                <div class="ps-banner__thumnail">
                  <img
                    class="ps-banner__round"
                    // src="/static/img/round5.png"
                    src={round5}
                    alt=""
                  />
                  <img
                    class="ps-banner__image"
                    // src="/static/img/girl-face-mask.png"
                    src={girlImg}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FranchiseSingleBanner.defaultProps = {
  logoImg: logoImg,
  virusIcon: virusIcon,
  ribbonIcon: ribbonIcon,
  round5: round5,
  girlImg: girlImg,
};

FranchiseSingleBanner.propTypes = {
  logoImg: PropTypes.string,
  virusIcon: PropTypes.string,
  ribbonIcon: PropTypes.string,
  round5: PropTypes.string,
  girlImg: PropTypes.string,
};

export default FranchiseSingleBanner;

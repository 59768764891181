import { Helmet } from "react-helmet";
import React from "react";
import WelcomePage from "../OurJourney/WelcomePage";

import founderImg from "../../assets/img/founder-desk.jpg";


const FounderDesk = () =>{

    React.useEffect(() => {
        // Scroll to the top with smooth behavior when the component mounts
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, []);


    return (
        <>
        <Helmet>
            <title>
                Founder's Desk - Drug Point
            </title>
        </Helmet>
        <main className="ps-page ps-page--inner">
        <div className="ps-page__content">
            <WelcomePage backgroundImage={founderImg} title="Founder's Desk"/>
        </div>
        </main>
        </>
    )
};

export default FounderDesk;

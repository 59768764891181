import React, { useEffect } from "react";
import FranchiseStepSection from "../../component/Franchise/FranchiseStepSection";
import FranchiseSingleBanner from "../../component/Franchise/FranchiseSingleBanner";
import FranchiseFullBanner from "../../component/Franchise/FranchiseFullBanner";
import FranchieForm from "../../component/Franchise/FranchiseForm";
import PageHelmet from "../../component/common/Helmet";
import TestimonialCarousel from "../../component/Slider/TestimonialCarousel";
import aboutImg from "../../assets/drugpoint/banner/drugpoint-banner-10.jpeg"


const FranchisePage = () => {

  useEffect(()=>{
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

  },[]);

  return (
    <>
    <React.Fragment>
      <PageHelmet title={"Franchisee"} />
    <main className="ps-page ps-page--inner">
        <div className="ps-page__content">
          <div className="ps-about">
            <div className="ps-about__content">
              {/* <AboutFullBanner /> */}
              <FranchiseFullBanner image={aboutImg} />
             
            </div>
            <div class="container">
              <FranchiseSingleBanner />
            <FranchiseStepSection />
            <FranchieForm />
            <TestimonialCarousel />
            </div>
           
          </div>
        </div>
      </main>
    </React.Fragment>
    
    </>
  );
};

export default FranchisePage;
